define("webapp/models/event-series", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    cronPattern: (0, _model.attr)('string', {
      defaultValue: '0 0 0 1/10 * ? *'
    }),
    endDate: (0, _model.attr)('date', {
      defaultValue: null
    }),
    eventItem: (0, _model.belongsTo)('event-item', {
      async: false
    })
  });

  _exports.default = _default;
});