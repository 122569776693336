define("webapp/models/permission", ["exports", "alpha-data/models/permission", "@ember-data/model"], function (_exports, _permission, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _permission.default.extend({
    key: (0, _model.attr)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});