define("webapp/pods/components/editor-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kHZLbaK8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"hide\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"unless\",[[24,[\"isHidden\"]]],null,{\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui info message\"],[8],[0,\"\\n  \"],[7,\"i\",false],[12,\"class\",\"close icon\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isHidden\"]]],null],true]],[8],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n    \"],[7,\"small\",true],[8],[0,\" \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"style\",\"float: right;\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isHidden\"]]],null],false]],[8],[0,\"Hilfe\"],[9],[0,\" \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/editor-notes/template.hbs"
    }
  });

  _exports.default = _default;
});