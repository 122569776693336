define("webapp/adapters/application", ["exports", "alpha-data/adapters/web-api", "webapp/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _webApi, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _webApi.default.extend(_dataAdapterMixin.default, {
    //authorizer: 'authorizer:jwt',
    host: _environment.default.api.host,
    namespace: 'api',
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
          idToken = _this$get.idToken;

      if (Ember.isPresent(idToken)) {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(idToken));
      } else {
        Ember.debug('Could not find the authorization token in the session data.');
      }
    }
  });

  _exports.default = _default;
});