define("webapp/models/image", ["exports", "@ember-data/model", "webapp/config/environment", "ember-custom-actions"], function (_exports, _model, _environment, _emberCustomActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    fileName: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    copyright: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    preview: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    unitKey: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    eventItemId: (0, _model.attr)('string'),
    eventItemImage: (0, _model.hasMany)('event-item-image', {
      async: false
    }),
    update: (0, _emberCustomActions.modelAction)('/'),
    downloadLink: Ember.computed('id', function () {
      return "".concat(_environment.default.api.host, "/api/images/").concat(this.id, "/download");
    })
  });

  _exports.default = _default;
});