define("webapp/pods/components/cron-input/monthly/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cron: null,
    disabled: false,
    onChange: function onChange(cron) {},
    _selectedIterator: Ember.computed('cron', function () {
      if (Ember.get(this, 'cron.weekDay').includes('#')) {
        return Ember.get(this, 'cron.weekDay').split('#')[1];
      } else {
        return Ember.get(this, 'cron.weekDay').substring(3);
      }
    }),
    _iterators: [{
      name: 'Ersten',
      value: 1
    }, {
      name: 'Zweiten',
      value: 2
    }, {
      name: 'Dritten',
      value: 3
    }, {
      name: 'Vierten',
      value: 4
    }, {
      name: 'Fünften',
      value: 5
    }, {
      name: 'Letzten',
      value: 'L'
    }],
    _selectedWeekDay: Ember.computed('cron', function () {
      return Ember.get(this, 'cron.weekDay').split('#')[0];
    }),
    _weekDays: [{
      value: 'MON',
      name: 'Montag'
    }, {
      value: 'TUE',
      name: 'Dienstag'
    }, {
      value: 'WED',
      name: 'Mittwoch'
    }, {
      value: 'THU',
      name: 'Donnerstag'
    }, {
      value: 'FRI',
      name: 'Freitag'
    }, {
      value: 'SAT',
      name: 'Samstag'
    }, {
      value: 'SUN',
      name: 'Sonntag'
    }],
    _selectedMonth: Ember.computed('cron', function () {
      return Ember.get(this, 'cron.month').split('/')[1];
    }),
    _months: Ember.computed('cron', function () {
      var months = [];

      for (var i = 1; i < 13; i++) {
        months.push({
          name: i + '.',
          value: i
        });
      }

      return months;
    }),
    actions: {
      onChangeIterator: function onChangeIterator(iterator) {
        var action = this.onChange;

        if (/\d/.test(iterator)) {
          action("0 0 * 1/".concat(this._selectedMonth, " ").concat(this._selectedWeekDay, "#").concat(iterator));
        } else {
          action("0 0 * 1/".concat(this._selectedMonth, " ").concat(this._selectedWeekDay).concat(iterator));
        } // Hack to prevent _selectedWeekDay to draw "(leer)"


        Ember.set(this, '_selectedWeekDay', this._selectedWeekDay);
      },
      onChangeWeekDay: function onChangeWeekDay(weekDay) {
        var action = this.onChange;
        action("0 0 * 1/".concat(this._selectedMonth, " ").concat(weekDay).concat(this._getCronIterator(this._selectedIterator)));
      },
      onChangeMonth: function onChangeMonth(month) {
        var action = this.onChange;
        action("0 0 * 1/".concat(month, " ").concat(this._selectedWeekDay).concat(this._getCronIterator(this._selectedIterator)));
      }
    },
    _getCronIterator: function _getCronIterator(iterator) {
      if (/\d/.test(iterator)) {
        return '#' + iterator;
      } else return iterator;
    }
  });

  _exports.default = _default;
});