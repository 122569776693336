define("webapp/services/auth0", ["exports", "ember-simple-auth-auth0/services/auth0"], function (_exports, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _auth.default;
    }
  });
});