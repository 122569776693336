define("webapp/models/payment-method", ["exports", "alpha-data/models/payment-method"], function (_exports, _paymentMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _paymentMethod.default;
    }
  });
});