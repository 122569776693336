define("webapp/models/process-log", ["exports", "alpha-data/models/process-log"], function (_exports, _processLog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _processLog.default;
    }
  });
});