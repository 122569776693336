define("webapp/models/item-tag", ["exports", "alpha-data/models/item-tag", "@ember-data/model"], function (_exports, _itemTag, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import DS from 'ember-data';
  var _default = _itemTag.default.extend({
    tagId: (0, _model.attr)('string', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});