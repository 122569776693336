define("webapp/pods/auth/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x9Sgr//e",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"key icon\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    Login\\n    \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"ADFC Radtouren- und Veranstaltungsportal\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui stacked segment\"],[8],[0,\"\\n\\n  \"],[7,\"button\",false],[12,\"class\",\"ui primary right labeled icon button\"],[3,\"action\",[[23,0,[]],\"login\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"right arrow icon\"],[8],[9],[0,\"\\n    Jetzt einloggen\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui bulleted list\"],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"https://touren-termine.adfc.de/p/datenschutzbestimmung\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[10,\"class\",\"item\"],[8],[0,\"Datenschutzerklärung\"],[9],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"https://touren-termine.adfc.de/p/impressum\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[10,\"class\",\"item\"],[8],[0,\"Impressum\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/auth/login/template.hbs"
    }
  });

  _exports.default = _default;
});