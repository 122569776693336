define("webapp/pods/modules/units/unit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    unitsList: Ember.inject.service(),
    model: function model(params) {
      return Ember.get(this, 'unitsList.content').findBy('id', params.unit_id);
    }
  });

  _exports.default = _default;
});