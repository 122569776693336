define("webapp/pods/components/alpha-textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UzOzXG6W",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"labelAddition\"]]],null,{\"statements\":[[0,\"          \"],[7,\"small\",true],[8],[0,\"(erforderlich, \"],[1,[22,\"labelAddition\"],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"small\",true],[8],[0,\"(erforderlich)\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"labelAddition\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"(\"],[1,[22,\"labelAddition\"],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[28,\"textarea\",null,[[\"type\",\"value\",\"disabled\",\"rows\",\"cols\",\"maxlength\"],[[24,[\"type\"]],[24,[\"value\"]],[24,[\"disabled\"]],[24,[\"rows\"]],[24,[\"cols\"]],[24,[\"maxlength\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"showChars\"]]],null,{\"statements\":[[7,\"small\",true],[8],[1,[22,\"charsLeft\"],false],[0,\" Zeichen verbleiben\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"class\",\"ui input error\"],[8],[1,[22,\"errorMessage\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/alpha-textarea/template.hbs"
    }
  });

  _exports.default = _default;
});