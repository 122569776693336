define("webapp/pods/components/alpha-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    selected: null,
    allowEmpty: false,
    emptyValue: null,
    disabled: false,
    required: null,
    fluid: true,
    error: null,
    emptyText: null,
    displayField: null,
    valueField: null,
    selector: null,
    onChange: function onChange() {},
    options: Ember.A(),
    errorObserver: Ember.observer('error', function () {
      this._checkErrors();
    }),
    onSelectedChange: Ember.observer('selected', function () {
      this._preset();
    }),
    didInsertElement: function didInsertElement() {
      var self = this;

      this._checkErrors();

      var selector = this.$('.dropdown').dropdown({
        apiSettings: {
          responseAsync: function responseAsync(settings, callback) {
            Ember.run.next(self, function () {
              var response = {
                success: true,
                results: Ember.get(self, 'options').map(function (item) {
                  if (!Ember.get(self, 'displayField')) {
                    return {
                      name: item,
                      value: item
                    };
                  }

                  var result = {};
                  result["name"] = Ember.get(item, Ember.get(self, 'displayField'));
                  result["value"] = Ember.get(item, Ember.get(self, 'valueField'));
                  return result;
                }).filter(function (result) {
                  if (!settings.urlData.query) {
                    return true;
                  }

                  return result['name'].toLowerCase().indexOf(settings.urlData.query.toLowerCase()) != -1;
                })
              };

              if (Ember.get(self, 'allowEmpty')) {
                response.results.push({
                  name: "",
                  value: self.emptyValue
                });
              }

              callback(response);
            });
          }
        },
        allowTab: false,
        fullTextSearch: true,
        forceSelection: false,
        message: {
          addResult: 'Add <b>{term}</b>',
          count: '{count} selected',
          maxSelections: 'Max {maxCount} selections',
          noResults: 'Keine Ergebnisse.'
        },
        onChange: function onChange(value) {
          if (_typeof(value) === undefined || Ember.get(self, 'clearing')) {
            Ember.set(self, 'clearing', false);
            return;
          }

          var action = Ember.get(self, 'onChange');

          if (value === Ember.get(self, 'selected')) {
            return;
          }

          Ember.set(self, 'error', null);

          if (!Ember.get(self, 'displayField') && !Ember.get(self, 'valueField')) {
            Ember.set(self, 'selected', value);
            action(value);
            return;
          }

          if (!Ember.get(self, 'valueField')) {
            var selected = Ember.get(self, 'options').findBy('id', value);
            Ember.set(self, 'selected', selected);
            action(value);
            return;
          }

          Ember.set(self, 'selected', value);
          action(value);
        },
        onShow: function onShow() {
          return true;
        }
      });

      if (this.isDestroyed) {
        return;
      }

      Ember.set(this, 'selector', selector);

      this._preset();
    },
    willDestroyElement: function willDestroyElement() {
      if (this.selector) {
        this.selector.dropdown('destroy');
      }
    },
    _checkErrors: function _checkErrors() {
      if (this.error) {
        var error = this.error[0];

        if (_typeof(error.message) === 'object') {
          error = error.message;
        }

        Ember.set(this, 'errorMessage', this.intl.t('a.validation.' + error.message, error.parameter));
        this.$().addClass('error');
      } else {
        Ember.set(this, 'errorMessage', null);
        this.$().removeClass('error');
      }
    },
    _preset: function _preset() {
      var self = this;
      var selector = this.selector;

      if (!this.selector) {
        return;
      }

      if (Ember.get(self, 'displayField')) {
        if (!Ember.get(self, 'valueField')) {
          Ember.set(self, 'valueField', 'id');
        }

        var obj = Ember.get(self, 'options').find(function (item) {
          return Ember.get(item, Ember.get(self, 'valueField')) == Ember.get(self, 'selected');
        });

        if (obj) {
          selector.dropdown('set text', Ember.get(obj, Ember.get(self, 'displayField')));
        } else {
          Ember.set(self, 'clearing', true);
          selector.dropdown('clear');
        }
      } else {
        selector.dropdown('set text', this.selected);
      }
    }
  });

  _exports.default = _default;
});