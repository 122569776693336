define("webapp/pods/components/module-selection/result-table/component", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userConfig: Ember.inject.service(),
    ////////////////////////////
    storedColumnConfigPrefix: 'column.',
    model: null,
    columns: null,
    onRowClick: null,
    onQueryChange: function onQueryChange() {},
    computedColumns: Ember.computed('model', 'orderColumn', 'orderDirection', function () {
      var self = this;
      var config = this.userConfig;
      var columns = Ember.A(this.columns).rejectBy('dataType', 'id');
      return columns.map(function (item) {
        var res = {
          label: Ember.get(item, 'caption'),
          valuePath: Ember.get(item, 'name'),
          resizable: true,
          minResizeWidth: 50,
          sorted: false,
          ascending: null
        };

        if (Ember.get(res, 'valuePath') == Ember.get(self, 'orderColumn')) {
          res.sorted = true;
          res.ascending = Ember.get(self, 'orderDirection') == 'asc' ? true : false;
        }

        if (Ember.get(item, 'dataType') == 'number') {
          res.width = '80px';
        }

        if (Ember.get(item, 'dataType') == 'date') {
          res.cellComponent = 'alpha-lt-cell-date';
        }

        if (Ember.get(item, 'name') == 'cStatus') {
          res.cellComponent = 'alpha-tl-cell-event-status';
        }

        if (Ember.get(item, 'dataType') == 'boolean') {
          res.cellComponent = 'alpha-lt-cell-boolean';
        }

        var configWidth = config.getItem(Ember.get(self, 'storedColumnConfigPrefix') + 'width.' + res.valuePath);

        if (configWidth) {
          res.width = configWidth;
        }

        return res;
      });
    }),
    table: Ember.computed('model', function () {
      return _emberLightTable.default.create({
        columns: this.computedColumns,
        rows: this.model
      }); //return new Table(this.computedColumns, this.model);
    }),
    computedClass: Ember.computed(function () {
      if (this.onRowClick) {
        return 'ui compact striped selectable celled table';
      }

      return 'ui compact striped celled table';
    }),
    actions: {
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          var orderDirection = column.ascending ? 'asc' : 'desc';
          var orderColumn = Ember.get(column, 'valuePath');
          var currentPage = 1;
          var action = this.onQueryChange;
          action(currentPage, orderColumn, orderDirection);
        }
      },
      onColumnResized: function onColumnResized(column, width) {
        var valuePath = Ember.get(column, 'valuePath');
        var config = this.userConfig;
        config.setItem(this.storedColumnConfigPrefix + 'width.' + valuePath, width);
      },
      pageChanged: function pageChanged(page) {
        var orderDirection = this.orderDirection;
        var orderColumn = this.orderColumn;
        var action = this.onQueryChange;
        action(page, orderColumn, orderDirection);
      }
    }
  });

  _exports.default = _default;
});