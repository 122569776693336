define("webapp/pods/components/events/calendar/options-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    unitsList: Ember.inject.service(),
    from: null,
    to: null,
    unitKey: null,
    onChange: function onChange() {},
    actions: {
      changed: function changed() {
        var action = this.onChange;
        action(Ember.getProperties(this, 'from', 'to', 'unitKey'));
      }
    }
  });

  _exports.default = _default;
});