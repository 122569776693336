define("webapp/pods/modules/events/event/finish/prepare-tour/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var eventItem = this.modelFor('modules.events.event');
      var prices = Ember.A();
      Ember.get(eventItem, 'eventItemPrices').forEach(function (item) {
        var price = {
          id: Ember.get(item, 'id'),
          kind: Ember.get(item, 'groupName'),
          amount: 0,
          isMember: Ember.get(item, 'cMemberPrice')
        };
        prices.pushObject(price);
      });

      var model = _actionModel.default.create();

      Ember.set(model, 'content', {
        additionalParticipants: prices,
        tourLength: Ember.get(eventItem, 'cFinishedTourLength'),
        recruitedMembers: Ember.get(eventItem, 'cRecruitedMembers')
      });
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('eventItem', this.modelFor('modules.events.event'));
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});