define("webapp/pods/modules/events/event/template/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gi10Qv2X",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Vorlage erstellen\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"Diese Veranstaltung kann als Vorlage für die Gliederung definiert werden. \"],[7,\"br\",true],[8],[9],[9],[0,\"\\n\\n\"],[7,\"form\",false],[12,\"class\",[29,[\"ui \",[28,\"if\",[[24,[\"createTemplate\",\"isRunning\"]],\"loading\"],null],\" form\"]]],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"createTemplate\"]]],null]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"name\",\"button\"],[10,\"class\",\"ui labeled icon primary button\"],[10,\"type\",\"submit\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"sticky note outline icon\"],[8],[9],[0,\" Als Vorlage definieren\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/events/event/template/template.hbs"
    }
  });

  _exports.default = _default;
});