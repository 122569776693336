define("webapp/pods/components/module-selection/selection-tabs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectionManager: Ember.inject.service(),
    selections: null,
    activeSelection: null,
    selectionChanged: function selectionChanged() {},
    onUpdateSelection: function onUpdateSelection() {},
    onDeleteSelection: function onDeleteSelection() {},
    onRenameSelection: function onRenameSelection() {},
    actions: {
      select: function select(selectionId) {
        if (this.activeSelection === selectionId) return;
        var action = this.selectionChanged;
        action(selectionId);
      },
      update: function update(selectionId) {
        var action = this.onUpdateSelection;
        action(selectionId);
      },
      remove: function remove(selectionId) {
        var action = this.onDeleteSelection;
        action(selectionId);
      },
      doubleClicked: function doubleClicked(selectionId) {
        var action = this.onRenameSelection;
        action(selectionId);
      }
    }
  });

  _exports.default = _default;
});