define("webapp/models/tour-location", ["exports", "@ember-data/model", "ember-custom-actions"], function (_exports, _model, _emberCustomActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    type: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    street: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    city: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    zipCode: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    latitude: (0, _model.attr)('number', {
      defaultValue: ''
    }),
    longitude: (0, _model.attr)('number', {
      defaultValue: ''
    }),
    beginning: (0, _model.attr)('date', {
      defaultValue: null
    }),
    end: (0, _model.attr)('date', {
      defaultValue: null
    }),
    position: (0, _model.attr)('number', {
      defaultValue: ''
    }),
    withoutTime: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    eventItem: (0, _model.belongsTo)('event-item', {
      async: false
    }),
    update: (0, _emberCustomActions.modelAction)('/'),
    address: Ember.computed('street', 'city', 'zipCode', function () {
      return this._formattedValue();
    }),
    _formattedValue: function _formattedValue() {
      if (this.street && this.zipCode && this.city) {
        return this.street + ", " + this.zipCode + " " + this.city;
      }

      return "";
    }
  });

  _exports.default = _default;
});