define("webapp/pods/components/events/index/selection-actions/delete/component", ["exports", "webapp/pods/components/events/index/selection-actions/base-modal/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    selectionManager: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this.parent, 'deleteModal', this);
    }
  });

  _exports.default = _default;
});