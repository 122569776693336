define("webapp/pods/components/alpha-progressive-image/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import InViewportMixin from 'ember-in-viewport';
  var _default = Ember.Component.extend(
  /*InViewportMixin, */
  {
    tagName: 'img',
    src: null,
    preview: null,
    didInsertElement: function didInsertElement() {
      //console.log('test');
      this.$().parent().css('overflow', 'hidden');
      var preview = this.preview;

      if (!preview) {
        this.$().addClass('finished').attr('src', this.src);
        return;
      }

      preview = 'data:image/png;base64,' + this.preview;
      this.$().css('background-image', 'url(' + preview + ')').css('background-size', '100% auto').attr('src', preview);
      Ember.run.later(this, function () {
        this.didEnterViewport();
      });
    },
    didEnterViewport: function didEnterViewport() {
      Ember.run.later(this, function () {
        var self = this;
        var curImg = new Image();
        curImg.src = this.src;

        curImg.onload = function () {
          self.$().addClass('finished').attr('src', Ember.get(self, 'src'));
        };
      });
    }
  });

  _exports.default = _default;
});