define("webapp/pods/modules/events/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    eventTypesList: Ember.inject.service(),
    unitsList: Ember.inject.service(),
    organizingUsersList: Ember.inject.service(),
    publishingUsersList: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return Ember.RSVP.all([this.eventTypesList, this.unitsList, this.organizingUsersList, this.publishingUsersList]);
    }
  });

  _exports.default = _default;
});