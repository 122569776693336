define("webapp/pods/components/alpha-location-select/unit-locations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pwCcvkzn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui two column grid\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"unitLocations\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"alpha-select\",null,[[\"label\",\"options\",\"displayField\",\"valueField\",\"class\",\"onChange\"],[\"Gliederungs-Standorte\",[24,[\"unitLocations\"]],\"address\",\"id\",\"sixteen wide field\",[28,\"action\",[[23,0,[]],\"onChange\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n          Keine Gliederungs-Standorte gefunden.\\n\"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/alpha-location-select/unit-locations/template.hbs"
    }
  });

  _exports.default = _default;
});