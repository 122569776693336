define("webapp/models/event-item", ["exports", "alpha-data/models/event-item", "ember-custom-actions", "@ember-data/model", "ember-get-config"], function (_exports, _eventItem, _emberCustomActions, _model, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import DS from 'ember-data';
  var _default = _eventItem.default.extend({
    cShortDescription: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    cUrl: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    cConditionsOfParticipation: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    cPublishDate: (0, _model.attr)('date', {
      defaultValue: null
    }),
    isTemplate: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    isDraft: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    isReview: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    isPublished: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    isCancelled: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    isFinished: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    isTour: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cEditorUserId: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    cOrganizingUserId: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    cSecondOrganizingUserId: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    cCanRequestReview: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cCanCancelReview: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cCanPublish: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cCancellationReason: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    cCanCancel: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cCanDelete: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cCanSupervise: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cTourLength: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cTourSpeed: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cTourSurface: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cTourClimb: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cTourHeight: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cTourDifficulty: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cCanFinish: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cParticipationCounter: (0, _model.attr)('number'),
    cSlug: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    cCanBeTemplate: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cWithoutTime: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cRegistrationType: (0, _model.attr)('string', {
      defaultValue: 'None'
    }),
    cExternalRegistrationUrl: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    cCanManageParticipants: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cTourLengthKm: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cTourSpeedKmh: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cExternalEvent: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cFinishedTourLength: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cRecruitedMembers: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    cIsBvRelevant: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cAdjustedTourDifficulty: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    eventItemFiles: (0, _model.hasMany)('event-item-file', {
      async: false
    }),
    eventItemImages: (0, _model.hasMany)('event-item-image', {
      async: false
    }),
    tourLocations: (0, _model.hasMany)('tour-location', {
      async: false
    }),
    cEventSeries: (0, _model.belongsTo)('event-series', {
      async: false
    }),
    cPublishUser: (0, _model.belongsTo)('user', {
      async: false
    }),
    recent: (0, _emberCustomActions.resourceAction)('recent', {
      type: 'GET'
    }),
    calendar: (0, _emberCustomActions.resourceAction)('calendar', {
      type: 'GET'
    }),
    templates: (0, _emberCustomActions.resourceAction)('templates', {
      type: 'GET'
    }),
    create: (0, _emberCustomActions.resourceAction)('/', {
      type: 'POST'
    }),
    update: (0, _emberCustomActions.modelAction)('/'),
    updateDescription: (0, _emberCustomActions.modelAction)('/updateDescription'),
    updateTourAttributes: (0, _emberCustomActions.modelAction)('/tourAttributes'),
    updateCAdjustedTourDifficulty: (0, _emberCustomActions.modelAction)('/cAdjustedTourDifficulty'),
    updateParticipationOptions: (0, _emberCustomActions.modelAction)('/participationOptions'),
    requestReview: (0, _emberCustomActions.modelAction)('/requestReview'),
    cancelReview: (0, _emberCustomActions.modelAction)('/cancelReview'),
    publish: (0, _emberCustomActions.modelAction)('/publish'),
    cancel: (0, _emberCustomActions.modelAction)('/cancel'),
    finish: (0, _emberCustomActions.modelAction)('/finish'),
    createTemplate: (0, _emberCustomActions.modelAction)('/createTemplate'),
    copy: (0, _emberCustomActions.modelAction)('/copy', {
      type: 'POST'
    }),
    messageParticipants: (0, _emberCustomActions.modelAction)('/messageParticipants', {
      type: 'POST'
    }),
    canGenerateInvoice: (0, _emberCustomActions.modelAction)('/canGenerateInvoice', {
      type: 'GET'
    }),
    getEventDates: (0, _emberCustomActions.modelAction)('/getEventDates', {
      type: 'GET'
    }),
    changelogs: (0, _emberCustomActions.modelAction)('changelogs', {
      type: 'GET'
    }),
    prepareTourFinish: (0, _emberCustomActions.modelAction)('/prepareTourFinish'),
    generateDefaultPrices: (0, _emberCustomActions.modelAction)('/generateDefaultPrices'),
    addPrice: (0, _emberCustomActions.modelAction)('/price', {
      type: 'POST'
    }),
    addUnitPrices: (0, _emberCustomActions.modelAction)('/unitPrices', {
      type: 'POST'
    }),
    addTourLocation: (0, _emberCustomActions.modelAction)('/tourLocation', {
      type: 'POST'
    }),
    addParticipant: (0, _emberCustomActions.modelAction)('/participant', {
      type: 'POST'
    }),
    updateParticipant: (0, _emberCustomActions.modelAction)('/participant', {
      type: 'PUT'
    }),
    acceptParticipant: (0, _emberCustomActions.modelAction)('/acceptParticipant', {
      type: 'PUT'
    }),
    cancelParticipantAndMail: (0, _emberCustomActions.modelAction)('/cancelParticipantAndMail', {
      type: 'PUT'
    }),
    updateCronPattern: (0, _emberCustomActions.modelAction)('/updateCronPattern', {
      type: 'PUT'
    }),
    removeFromSeries: (0, _emberCustomActions.modelAction)('/removeFromSeries', {
      type: 'PUT'
    }),
    addPoolImage: (0, _emberCustomActions.modelAction)('/addPoolImage', {
      type: 'PUT'
    }),
    cCanChangePublishStatus: Ember.computed('cCanCancelReview', 'cCanRequestReview', 'cCanPublish', function () {
      return this.isDraft || this.isReview;
    }),
    cPreviewLink: Ember.computed('cSlug', function () {
      return _emberGetConfig.default.portalUrl + '/radveranstaltung/' + this.cSlug;
    }),
    imagesValid: Ember.computed('eventItemImages.@each.hasCopyright', function () {
      if (!Ember.get(this, 'eventItemImages.length')) return false;
      var result = this.eventItemImages.any(function (item) {
        return Ember.get(item, 'image.copyright');
      });
      return result;
    }),
    attributesValid: Ember.computed('eventType', 'cTourDifficulty', 'lastUpdate', function () {
      var itemTags = this.store.peekAll('item-tag').filterBy('itemId', this.id);

      if (this.eventType == "Radtour") {
        if (this.cTourDifficulty == 0) {
          return false;
        }

        var typeCategory = itemTags.find(function (i) {
          return Ember.get(i, 'category') === 'Typen (nach Dauer und Tageslage)';
        });

        if (!typeCategory) {
          return false;
        }

        var suitableFor = itemTags.find(function (i) {
          return Ember.get(i, 'category') === 'Geeignet für';
        });

        if (!suitableFor) {
          return false;
        }
      }

      if (!Ember.get(itemTags, 'length')) {
        return false;
      }

      return true;
    }),
    cCanEditEventSeries: Ember.computed('isTemplate', 'isDraft', 'isReview', function () {
      return this.isTemplate || this.isDraft || this.isReview;
    }),
    isSeriesVisible: Ember.computed('isTemplate', 'isPublished', 'isCancelled', 'isFinished', 'cEventSeries', function () {
      var isTemplate = this.isTemplate;
      var isPublished = this.isPublished;
      var isCancelled = this.isCancelled;
      var isFinished = this.isFinished;
      var cEventSeries = this.cEventSeries;

      if (isTemplate) {
        return false;
      }

      if ((isPublished || isCancelled || isFinished) && cEventSeries == null) {
        return false;
      }

      return true;
    }),
    canBeRemovedFromSeries: Ember.computed('isPublished', 'isCancelled', 'isFinished', 'cEventSeries', function () {
      var isPublished = this.isPublished;
      var isCancelled = this.isCancelled;
      var isFinished = this.isFinished;
      var cEventSeries = this.cEventSeries;

      if ((isPublished || isCancelled || isFinished) && cEventSeries != null) {
        return true;
      }

      return false;
    }),
    canBeSeries: Ember.computed('isPublished', 'isCancelled', 'isFinished', function () {
      var isPublished = this.isPublished;
      var isCancelled = this.isCancelled;
      var isFinished = this.isFinished;

      if (isPublished || isCancelled || isFinished) {
        return false;
      }

      return true;
    }),
    timeCanNotBeAltered: Ember.computed('isCancelled', 'isFinished', 'cEventSeries', function () {
      var isCancelled = this.isCancelled;
      var isFinished = this.isFinished;
      var cEventSeries = this.cEventSeries;

      if (isCancelled || isFinished || cEventSeries != null) {
        return true;
      }

      return false;
    }),
    sortedTourLocations: Ember.computed('tourLocations.[]', function () {
      return this.tourLocations.sortBy('beginning');
    })
  });

  _exports.default = _default;
});