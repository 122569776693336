define("webapp/pods/components/events/index/selection-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uOQ1l09G",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui right floated dropdown icon button\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"cog icon\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n      Selektionseinträge\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"divider\"],[8],[9],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"item\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"requestReview\"]],[8],[0,\"Zur Veröffentlichung freigeben\"],[9],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"item\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"publish\"]],[8],[0,\"Veröffentlichen\"],[9],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"item\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[0,\"Stornieren\"],[9],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"item\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"delete\"]],[8],[0,\"Löschen\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[1,[28,\"events/index/selection-actions/request-review\",null,[[\"parent\"],[[23,0,[]]]]],false],[0,\"\\n\"],[1,[28,\"events/index/selection-actions/publish\",null,[[\"parent\"],[[23,0,[]]]]],false],[0,\"\\n\"],[1,[28,\"events/index/selection-actions/cancel\",null,[[\"parent\"],[[23,0,[]]]]],false],[0,\"\\n\"],[1,[28,\"events/index/selection-actions/delete\",null,[[\"parent\"],[[23,0,[]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/events/index/selection-actions/template.hbs"
    }
  });

  _exports.default = _default;
});