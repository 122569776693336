define("webapp/pods/components/adfc-medium-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: null,
    _selector: null,
    _prevValue: null,
    onChange: function onChange() {},
    didInsertElement: function didInsertElement() {
      var _this = this;

      var onChangeHandler = this.onChange;
      var editor = new MediumEditor(this.$(), {
        placeholder: {
          text: 'Bitte geben Sie hier Ihren Text ein...'
        }
      });
      editor.setContent(this.value);
      Ember.set(this, '_prevValue', this.value);

      var handler = function handler() {
        var newValue = editor.getContent();
        var isUpdated = _this._prevValue !== newValue;

        if (isUpdated) {
          Ember.set(_this, '_prevValue', newValue);
          onChangeHandler(newValue);
        }
      };

      editor.subscribe('editableInput', handler);
      Ember.set(this, '_selector', editor);
    },
    willDestroyElement: function willDestroyElement() {
      var editor = this._selector;

      if (Ember.isPresent(editor)) {
        editor.destroy();
      }
    }
  });

  _exports.default = _default;
});