define("webapp/components/alpha-pagination", ["exports", "alpha-components/components/alpha-pagination"], function (_exports, _alphaPagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _alphaPagination.default;
    }
  });
});