define("webapp/pods/modules/users/create/route", ["exports", "webapp/utils/action-model", "webapp/mixins/routes/dirty-confirm"], function (_exports, _actionModel, _dirtyConfirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dirtyConfirm.default, {
    unitsList: Ember.inject.service(),
    rolesList: Ember.inject.service(),
    rolesSortProperties: ["name:desc"],
    sortedRolesList: Ember.computed.sort("rolesList.content.[]", "rolesSortProperties"),
    beforeModel: function beforeModel() {
      return Ember.RSVP.all([this.unitsList, this.rolesList]);
    },
    model: function model() {
      var model = _actionModel.default.create();

      model.set("content", {
        eMailAddress: "",
        salutation: "",
        firstName: "",
        lastName: "",
        roleId: Ember.get(this, "sortedRolesList.firstObject.id"),
        unitId: Ember.get(this, "unitsList.content.firstObject.id")
      });
      model.applyBufferedChanges();
      return model;
    }
  });

  _exports.default = _default;
});