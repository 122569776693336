define("webapp/pods/components/module-selection/result-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1HEsgTDu",
    "block": "{\"symbols\":[\"t\",\"bd\"],\"statements\":[[4,\"light-table\",[[24,[\"table\"]]],[[\"tableClassNames\"],[[24,[\"computedClass\"]]]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"head\"]],\"expected `t.head` to be a contextual component but found a string. Did you mean `(component t.head)`? ('webapp/pods/components/module-selection/result-table/template.hbs' @ L3:C4) \"],null]],[[\"onColumnClick\",\"onColumnResized\",\"iconSortable\",\"iconAscending\",\"iconDescending\"],[[28,\"action\",[[23,0,[]],\"onColumnClick\"],null],[28,\"action\",[[23,0,[]],\"onColumnResized\"],null],\"sort icon\",\"sort amount up icon\",\"sort amount down icon\"]]],false],[0,\"\\n\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"body\"]],\"expected `t.body` to be a contextual component but found a string. Did you mean `(component t.body)`? ('webapp/pods/components/module-selection/result-table/template.hbs' @ L11:C5) \"],null]],[[\"onRowClick\"],[[24,[\"onRowClick\"]]]],{\"statements\":[[4,\"if\",[[24,[\"table\",\"isEmpty\"]]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"no-data\"]],\"expected `bd.no-data` to be a contextual component but found a string. Did you mean `(component bd.no-data)`? ('webapp/pods/components/module-selection/result-table/template.hbs' @ L13:C9) \"],null]],null,{\"statements\":[[0,\"        Keine Ergebnisse gefunden.\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"currentPage\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"br\",true],[8],[9],[0,\"\\n\\n  \"],[1,[28,\"alpha-pagination\",null,[[\"meta\",\"page\",\"onPageChanged\"],[[24,[\"meta\"]],[24,[\"currentPage\"]],[28,\"action\",[[23,0,[]],\"pageChanged\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/module-selection/result-table/template.hbs"
    }
  });

  _exports.default = _default;
});