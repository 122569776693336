define("webapp/pods/modules/events/event/changelog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1fRH139T",
    "block": "{\"symbols\":[\"changelog\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Historie\"],[9],[0,\"\\n\\n\"],[7,\"table\",true],[10,\"class\",\"ui celled striped table\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"\\n        Datum\\n      \"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"\\n        Feld\\n      \"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"\\n        Alt\\n      \"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"\\n        Neu\\n      \"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"\\n        Benutzer\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"changeLogs\"]]],null,{\"statements\":[[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[1,[28,\"format-date\",[[23,1,[\"timeOfChange\"]]],[[\"format\"],[\"full\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"changedInfo\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"oldValue\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"newValue\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[\"modules.users.user\",[23,1,[\"userId\"]]]],{\"statements\":[[0,\" \"],[1,[23,1,[\"firstName\"]],false],[0,\" \"],[1,[23,1,[\"lastName\"]],false],[0,\" \"]],\"parameters\":[]},null],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[10,\"colspan\",\"2\"],[8],[0,\"Keine Dateien gefunden\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/events/event/changelog/template.hbs"
    }
  });

  _exports.default = _default;
});