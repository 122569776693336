define("webapp/pods/modules/events/event/participation-options/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var eventItem = this.modelFor('modules.events.event');

      var model = _actionModel.default.create();

      model.set('content', {
        closingDate: null,
        maximum: 0
      });
      model.fill(eventItem);
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, 'eventItem', this.modelFor('modules.events.event'));
    }
  });

  _exports.default = _default;
});