define("webapp/pods/modules/events/event/cancel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yoi/WDXc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Stornieren\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"Die Stornierung kann nicht rückgängig gemacht werden.\"],[9],[0,\"\\n\"],[7,\"form\",false],[12,\"class\",\"ui form\"],[3,\"action\",[[23,0,[]],\"delete\",[24,[\"model\"]]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[1,[28,\"alpha-textarea\",null,[[\"label\",\"labelAddition\",\"value\",\"error\",\"rows\",\"required\",\"class\"],[\"Stornierungsgrund\",\"wird auf der Veranstaltungsseite angezeigt\",[24,[\"model\",\"cancellationReason\"]],[24,[\"model\",\"errors\",\"cancellationReason\"]],3,true,\"sixteen wide field\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\\n  \"],[7,\"button\",true],[10,\"name\",\"button\"],[10,\"class\",\"ui labeled icon red button\"],[11,\"disabled\",[29,[[28,\"unless\",[[24,[\"model\",\"hasBufferedChanges\"]],\"disabled\"],null]]]],[10,\"type\",\"submit\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"remove icon\"],[8],[9],[0,\" Stornierung bestätigen\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/events/event/cancel/template.hbs"
    }
  });

  _exports.default = _default;
});