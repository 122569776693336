define("webapp/pods/modules/users/user/history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gdgfC92C",
    "block": "{\"symbols\":[\"log\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Historie\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[0,\"\\n  Hier können Sie die Änderungshistorie des Benutzers einsehen.\\n\"],[9],[0,\"\\n\"],[7,\"table\",true],[10,\"class\",\"ui celled table\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Eigenschaft\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Alter Wert\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Neuer Wert\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Änderungsdatum\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"info\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"oldValue\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"newValue\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"dateTime\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/users/user/history/template.hbs"
    }
  });

  _exports.default = _default;
});