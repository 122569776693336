define("webapp/pods/components/custom-tui-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: null,
    allowImages: false,
    onChange: function onChange() {},
    init: function init() {
      this._super.apply(this, arguments);

      var items = ['heading', 'bold', 'italic', //'strike',
      'divider', //'hr',
      //'quote',
      //'ul',
      //'ol',
      //'task',
      //'divider',
      //'table',
      //'image',
      'link' //'divider',
      //'code',
      //'codeblock'
      ];

      if (this.allowImages) {
        items.push('image');
      }

      Ember.set(this, '_toolbarItems', items);
    }
  });

  _exports.default = _default;
});