define("webapp/pods/modules/events/event/copy/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var model = _actionModel.default.create();

      model.set("content", {
        includeFiles: true,
        includePrices: true,
        includeLocations: true
      });
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, "eventItem", this.modelFor("modules.events.event"));
    }
  });

  _exports.default = _default;
});