define("webapp/pods/components/alpha-confirm/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    confirm: Ember.inject.service(),
    text: null,
    _selector: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.once(this, function () {
        this.confirm.on('showModal', this, this._showModal);
      });
    },
    didInsertElement: function didInsertElement() {
      var selector = this.$('.ui.modal');
      Ember.set(this, '_selector', selector);
    },
    willDestroyElement: function willDestroyElement() {
      var selector = this._selector;

      if (Ember.isPresent(selector)) {
        selector.modal('destroy');
      }

      this.confirm.off('showModal', this, this._showModal);
    },
    _showModal: function _showModal(options) {
      Ember.set(this, 'text', options.text);
      var confirm = this.confirm;
      var selector = this._selector;
      selector.modal({
        closable: false,
        duration: 100,
        transition: 'slide',
        onApprove: function onApprove() {
          confirm.trigger('closed', true);
        },
        onDeny: function onDeny() {
          confirm.trigger('closed', false);
        }
      });
      selector.modal('show');
    }
  });

  _exports.default = _default;
});