define("webapp/pods/components/alpha-calendar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  // https://github.com/mdehoog/Semantic-UI-Calendar
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    required: null,
    error: null,
    placeholder: null,
    value: null,
    type: 'date',
    minDate: null,
    maxDate: null,
    startMode: false,
    initialDate: null,
    disabled: false,
    _selector: null,
    errorObserver: Ember.observer('error', function () {
      this._checkErrors();
    }),
    valueObserver: Ember.observer('value', function () {
      var selector = this._selector;
      var newValue = this.value;
      var currentValue = selector.calendar('get date');

      if (newValue != currentValue) {
        selector.calendar('set date', newValue);
      }
    }),
    disabledObserver: Ember.observer('disabled', function () {
      var selector = this._selector;

      if (selector) {
        selector.calendar('refresh');
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.minDate = new Date(2017, 1, 1);
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      var selector = this.$('.ui.calendar').calendar({
        type: this.type,
        minDate: this.minDate,
        maxDate: this.maxDate,
        startMode: this.startMode,
        initialDate: this.initialDate,
        firstDayOfWeek: 1,
        monthFirst: false,
        ampm: false,
        text: {
          days: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
          months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
          monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
          today: 'Heute',
          now: 'Jetzt',
          am: 'AM',
          pm: 'PM'
        },
        onChange: function onChange(date) {
          if (Ember.get(self, 'value') == date) {
            return;
          }

          Ember.set(self, 'error', null);
          var action = Ember.get(self, 'onChange');

          if (!date) {
            action(null);
            return;
          }

          action(date);
        },
        isDisabled: function isDisabled() {
          return Ember.get(self, 'disabled');
        },
        parser: {
          date: function date(text, settings) {
            var type = Ember.get(self, 'type');

            if (type == 'datetime') {
              var textSplit = text.split(" ");

              if (textSplit.length != 2) {
                return null;
              }

              var date = textSplit[0];
              var time = textSplit[1];
              var dateSplit = date.split(".");

              if (dateSplit.length != 3) {
                return null;
              }

              var day = dateSplit[0];
              var month = dateSplit[1];
              var year = dateSplit[2];

              if (day === "" || day.length < 2 || month === "" || month.length < 2 || year === "" || year.length < 4) {
                return null;
              }

              var timeSplit = time.split(":");

              if (timeSplit.length != 2) {
                return null;
              }

              var hour = timeSplit[0];
              var minute = timeSplit[1];

              if (hour === "" || hour.length < 2 || minute === "" || minute.length < 2) {
                return null;
              }

              return new Date(year, month - 1, day, hour, minute);
            } else {
              var _dateSplit = text.split(".");

              if (_dateSplit.length != 3) {
                return null;
              }

              var _day = _dateSplit[0];
              var _month = _dateSplit[1];
              var _year = _dateSplit[2];

              if (_day === "" || _day.length < 2 || _month === "" || _month.length < 2 || _year === "" || _year.length < 4) {
                return null;
              }

              var now = new Date();

              var _hour = now.getHours();

              var _minute = now.getMinutes();

              return new Date(_year, _month - 1, _day, _hour, _minute);
            }
          }
        }
      });
      var value = this.value;

      if (typeof value === 'string') {
        value = new Date(value);
      }

      selector.calendar('set date', value);
      Ember.set(this, '_selector', selector);

      this._checkErrors();
    },
    onChange: function onChange() {
      (false && !(false) && Ember.assert("You have to provide an action to `alpha-calender.onChange`"));
    },
    _checkErrors: function _checkErrors() {
      if (this.error) {
        var error = this.error[0];

        if (_typeof(error.message) === 'object') {
          error = error.message;
        }

        Ember.set(this, 'errorMessage', this.intl.t('a.validation.' + error.message, error.parameter));
        this.$().addClass('error');
      } else {
        Ember.set(this, 'errorMessage', null);
        this.$().removeClass('error');
      }
    }
  });

  _exports.default = _default;
});