define("webapp/services/tags-list", ["exports", "alpha-data/mixins/ressource-list-mixin"], function (_exports, _ressourceListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_ressourceListMixin.default, {
    modelName: 'tag',
    grouped: Ember.computed('content', function () {
      return this.content.reduce(function (r, item) {
        var category = r.findBy('category', Ember.get(item, 'category'));

        if (!category) {
          category = {
            category: Ember.get(item, 'category'),
            required: Ember.get(item, 'cRequired'),
            type: Ember.get(item, 'cType'),
            items: Ember.A()
          };
          r.pushObject(category);
        }

        Ember.get(category, 'items').pushObject(item);
        return r;
      }, Ember.A());
    })
  });

  _exports.default = _default;
});