define("webapp/pods/components/editor-notes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['hide:hidden'],
    hide: false,
    isHidden: true
  });

  _exports.default = _default;
});