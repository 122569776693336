define("webapp/pods/components/alpha-progress/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    throttle: 1000,
    onChange: Ember.observer('value', function () {
      if (this.active && this.progress) {
        Ember.run.throttle(this, this.updateValue, this.throttle);
      }
    }),
    updateValue: function updateValue() {
      var value = this.value / this.total * 100;

      if (value > 99) {
        value = 99;
      }

      this.progress.progress('set percent', value);
    },
    didInsertElement: function didInsertElement() {
      this.set('active', true);
      var progress = this.$('.progress').progress({
        value: this.value,
        total: this.total,
        label: 'ratio',
        text: {
          ratio: '{percent}%'
        }
      });
      this.set('progress', progress);
    },
    willDestroyElement: function willDestroyElement() {
      this.set('active', false);
    }
  });

  _exports.default = _default;
});