define("webapp/pods/modules/events/event/tour-difficulties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SNoDEsTR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Anpassen der Radtourschwierigkeit\"],[9],[0,\"\\n\\n\"],[7,\"form\",false],[12,\"class\",[29,[\"ui \",[28,\"if\",[[24,[\"update\",\"isRunning\"]],\"loading\"],null],\" form\"]]],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"update\"]],[24,[\"model\"]]],null]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[28,\"eq\",[\"Radtour\",[24,[\"eventItem\",\"eventType\"]]],null],[28,\"not-eq\",[[24,[\"evemtItem\",\"cTourDifficulty\"]],0],null]],null]],null,{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"fields\"],[8],[0,\"\\n        \"],[1,[28,\"alpha-select\",null,[[\"label\",\"required\",\"error\",\"options\",\"displayField\",\"valueField\",\"selected\",\"class\"],[\"Schwierigkeitsgrad\",true,[24,[\"model\",\"errors\",\"cTourSpeed\"]],[24,[\"tourDifficultiesList\",\"content\"]],\"tourDifficulty\",\"tourDifficulty\",[24,[\"model\",\"cAdjustedTourDifficulty\"]],\"sixteen wide field\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"button\",true],[10,\"name\",\"button\"],[10,\"class\",\"ui labeled icon primary button\"],[11,\"disabled\",[29,[[28,\"unless\",[[24,[\"model\",\"hasBufferedChanges\"]],\"disabled\"],null]]]],[10,\"type\",\"submit\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"edit icon\"],[8],[9],[0,\" Speichern\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/events/event/tour-difficulties/template.hbs"
    }
  });

  _exports.default = _default;
});