define("webapp/translations/de-de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "a": {
      "validation": {
        "cValidUrl": "URL ist ungültig.",
        "email": "E-Mail-Adresse ist ungültig.",
        "equal": "Muss gleich sein",
        "greaterThan": "Muss größer als {comparisionValue} sein.",
        "greaterThanOrEqual": "Muss größer oder gleich {comparisionValue} sein.",
        "lessThan": "Muss kleiner als  {comparisionValue} sein.",
        "lessThanOrEqual": "Muss kleiner oder gleich {comparisionValue} sein.",
        "maximumLength": "Darf maximal {maxLength} Zeichen enthalten.",
        "notEmpty": "Darf nicht leer sein.",
        "notEqual": "Darf nicht gleich sein.",
        "unique": "Ist bereits vergeben.",
        "wrong": "Format nicht erkannt"
      }
    }
  };
  _exports.default = _default;
});