define("webapp/pods/components/cron-input/weekly/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mvpsm1ZF",
    "block": "{\"symbols\":[\"weekDay\"],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui horizontal list\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n    Jede Woche am\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"_weekDays\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"alpha-checkbox\",null,[[\"label\",\"checked\",\"onchange\",\"disabled\"],[[23,1,[\"name\"]],[23,1,[\"checked\"]],[28,\"action\",[[23,0,[]],\"onChange\",[23,1,[]]],null],[24,[\"disabled\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/cron-input/weekly/template.hbs"
    }
  });

  _exports.default = _default;
});