define("webapp/pods/components/custom-tui-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ICch5HFT",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"tui-editor\",null,[[\"value\",\"onChange\",\"toolbarItems\",\"height\",\"usageStatistics\",\"editType\",\"previewStyle\",\"language\",\"hideModeSwitch\"],[[24,[\"value\"]],[24,[\"onChange\"]],[24,[\"_toolbarItems\"]],\"400px\",false,\"wysiwyg\",\"vertical\",\"de\",true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/custom-tui-editor/template.hbs"
    }
  });

  _exports.default = _default;
});