define("webapp/models/tour-difficulty", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    tourDifficulty: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    minValue: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    maxValue: (0, _model.attr)('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});