define("webapp/models/unit", ["exports", "alpha-data/models/unit", "@ember-data/model", "ember-custom-actions"], function (_exports, _unit, _model, _emberCustomActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import DS from 'ember-data';
  var _default = _unit.default.extend({
    cOrganizerShare: (0, _model.attr)('number'),
    iban: (0, _model.attr)('string'),
    cInvoiceReady: (0, _model.attr)('boolean'),
    unitPriceLists: (0, _model.hasMany)('unit-price-list'),
    unitLocations: (0, _model.hasMany)('unit-location'),
    update: (0, _emberCustomActions.modelAction)('/'),
    displayName: Ember.computed('key', 'name', function () {
      var key = this.key;
      var prefix = null;

      switch (key.length) {
        case 1:
          prefix = null;
          break;

        case 3:
          prefix = "".concat(key.substr(1, 2));
          break;

        case 6:
          prefix = "".concat(key.substr(1, 2), "-").concat(key.substr(3, 3));
          break;

        case 8:
          prefix = "".concat(key.substr(1, 2), "-").concat(key.substr(3, 3), "-").concat(key.substr(6, 2));
          break;

        default:
      }

      if (!prefix) {
        return this.name;
      }

      return "".concat(prefix, " - ").concat(this.name);
    })
  });

  _exports.default = _default;
});