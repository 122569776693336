define("webapp/pods/modules/units/unit/images/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var unit = this.modelFor('modules.units.unit');
      return this.store.query('image', {
        unitKey: Ember.get(unit, 'key')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, 'unit', this.modelFor('modules.units.unit'));
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});