define("webapp/pods/components/events/event/tour-locations/form-fields/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tourLocationTypesList: Ember.inject.service(),
    unitsList: Ember.inject.service(),
    tourLocationModel: null,
    unitId: null,
    didInsertElement: function didInsertElement() {
      var self = this;

      self._super.apply(self, arguments);

      self.getUnitId(self.unitsList.result);
    },
    getUnitId: function getUnitId(list) {
      var self = this;
      list.forEach(function (item) {
        if (item.key == Ember.get(self, 'tourLocationModel.unitKey')) {
          Ember.set(self, 'unitId', item.unitId);
          return;
        }
      });
    }
  });

  _exports.default = _default;
});