define("webapp/pods/modules/events/event/message-participants/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      var sendModel = _actionModel.default.create();

      sendModel.set("content", {
        message: this._getMessage(),
        includeEmail: true,
        isPreview: true
      });
      return sendModel;
    },
    _getMessage: function _getMessage() {
      return "Liebe*r Fahrradfreund*in,\n\n\n\n Mit freundlichen Gr\xFC\xDFen,\n ".concat(Ember.get(this, "currentUser.model.firstName"), " ").concat(Ember.get(this, "currentUser.model.lastName"));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, 'eventItem', this.modelFor('modules.events.event'));
    }
  });

  _exports.default = _default;
});