define("webapp/models/publishing-user", ["exports", "webapp/models/user", "@ember-data/model"], function (_exports, _user, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _user.default.extend({
    unitKey: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    unitName: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    displayName: Ember.computed('firstName', 'lastName', 'unitName', function () {
      return "".concat(this.firstName, " ").concat(this.lastName, " - ").concat(this.unitName);
    })
  });

  _exports.default = _default;
});