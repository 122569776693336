define("webapp/pods/components/alpha-location-select/direct-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      selectStreet: function selectStreet() {
        var action = this.onSelect;
        action();
      }
    }
  });

  _exports.default = _default;
});