define("webapp/pods/modules/events/event/tour-attributes/route", ["exports", "webapp/utils/action-model", "webapp/mixins/routes/dirty-confirm"], function (_exports, _actionModel, _dirtyConfirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dirtyConfirm.default, {
    tourAttributesList: Ember.inject.service(),
    tagsList: Ember.inject.service(),
    types: Ember.A(),
    beforeModel: function beforeModel() {
      return Ember.RSVP.all([this.tourAttributesList, this.tagsList]);
    },
    model: function model() {
      var eventItem = this.modelFor('modules.events.event');
      var store = this.store;

      var model = _actionModel.default.create();

      var tags = store.peekAll('item-tag').filterBy('itemId', Ember.get(eventItem, 'id')).getEach('tagId').join('||');
      Ember.set(model, 'content', {
        cTourLength: 0,
        cTourSpeed: 0,
        cTourSurface: 0,
        cTourHeight: 0,
        cTourClimb: 0,
        cTourLengthKm: 0,
        cTourSpeedKmh: 0,
        tags: tags
      });
      model.fill(eventItem);
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, 'eventItem', this.modelFor('modules.events.event'));
    }
  });

  _exports.default = _default;
});