define("webapp/pods/modules/events/event/documents/participant-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XRuLTRVa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"alpha-task-button\",null,[[\"class\",\"task\"],[\"ui labeled icon button\",[24,[\"exportParticipantList\"]]]],{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"download icon\"],[8],[9],[0,\" Teilnehmerliste herunterladen\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/events/event/documents/participant-list/template.hbs"
    }
  });

  _exports.default = _default;
});