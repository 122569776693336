define("webapp/pods/components/form-user/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    salutationsList: Ember.inject.service(),
    unitsList: Ember.inject.service(),
    rolesList: Ember.inject.service(),
    task: null,
    model: null,
    updating: false,
    sortProperties: ['name:desc'],
    sortedRolesList: Ember.computed.sort('rolesList.content.[]', 'sortProperties'),
    actions: {
      save: function save(model) {
        this.task.perform(model);
      }
    }
  });

  _exports.default = _default;
});