define("webapp/pods/components/alpha-sticky/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    loading: null,
    isSticking: false,
    loadingObserver: Ember.observer('loading', function () {
      if (!this.isDestroying && !this.isDestroyed) {
        try {
          this.$('.ui.sticky').sticky('refresh');
        } catch (error) {//
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      var self = this;
      this.$('.ui.sticky').sticky({
        onStick: function onStick() {
          if (!Ember.get(self, 'isSticking')) {
            try {
              Ember.set(self, 'isSticking', true);
              self.$('.sticky-wrapper').height(self.$(this).height());
            } catch (error) {//
            }
          }
        },
        onUnstick: function onUnstick() {
          try {
            self.$('.ui.sticky').sticky('refresh');
            Ember.set(self, 'isSticking', false);
          } catch (error) {//
          }
        }
      });
      this.$(window).bind('resize', this.resizeHandler);
    },
    didRender: function didRender() {
      try {
        this.$('.ui.sticky').sticky('refresh');
        this.$('.sticky-wrapper').height(this.$('.ui.sticky').height());
      } catch (error) {//
      }
    },
    willDestroyElement: function willDestroyElement() {
      try {
        this.$(window).unbind('resize', this.resizeHandler);
        self.$('.ui.sticky').sticky('destroy');
      } catch (error) {//
      }
    },
    resizeHandler: function resizeHandler() {
      try {
        Ember.run.debounce(self, function () {
          self.$('.sticky-wrapper').height(self.$('.ui.sticky').height());
        }, 100);
      } catch (error) {//
      }
    }
  });

  _exports.default = _default;
});