define("webapp/pods/components/alpha-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    //Attributes
    value: null,
    disabled: null,
    required: null,
    icon: null,
    error: null,
    type: 'text',
    step: null,
    focused: false,
    focusOut: function focusOut() {},
    keyUp: function keyUp() {},
    errorObserver: Ember.observer('error', function () {
      this._checkErrors();
    }),
    valueObserver: Ember.observer('value', function () {
      if (this.value != null) {
        this.set('error', null);
      }
    }),
    didInsertElement: function didInsertElement() {
      var self = this;

      this._checkErrors();

      var timer = null;

      if (this.focused) {
        timer = Ember.run.later(self, function () {
          this.$('input').focus();
        }, 400);
      }

      if (this.select) {
        timer = Ember.run.scheduleOnce('afterRender', this, function () {
          this.$('input').select();
        });
      }

      Ember.set(this, '_timer', timer);
    },
    willDestroyElement: function willDestroyElement() {
      var timer = this._timer;

      if (timer) {
        Ember.run.cancel(timer);
      }
    },
    actions: {
      focusOut: function focusOut() {
        var action = this.focusOut;
        action(this.value);
      },
      keyUp: function keyUp() {
        var action = this.keyUp;
        action(this.value);
      }
    },
    _checkErrors: function _checkErrors() {
      if (this.error) {
        var error = this.error[0];

        if (_typeof(error.message) === 'object') {
          error = error.message;
        }

        this.set('errorMessage', this.intl.t('a.validation.' + error.message, error.parameter));
        this.$().addClass('error');
      } else {
        this.set('errorMessage', null);
        this.$().removeClass('error');
      }
    }
  });

  _exports.default = _default;
});