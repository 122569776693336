define("webapp/pods/components/units/index-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onRowClick: function onRowClick() {},
    actions: {
      rowClick: function rowClick(unit) {
        this.onRowClick(unit);
      }
    }
  });

  _exports.default = _default;
});