define("webapp/pods/components/events/calendar/event-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vC1CmTVu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui top attached segment\"],[8],[0,\"\\n  \"],[1,[28,\"events/calendar/options-bar\",null,[[\"from\",\"to\",\"unitKey\",\"onChange\"],[[24,[\"from\"]],[24,[\"to\"]],[24,[\"unitKey\"]],[28,\"perform\",[[24,[\"loadTask\"]]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui bottom attached \",[28,\"if\",[[28,\"or\",[[24,[\"loadTask\",\"isRunning\"]],[24,[\"loading\"]]],null],\"loading\"],null],\" segment\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"result\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"chart\"],[10,\"id\",\"customChart\"],[10,\"style\",\"height: 500px;\"],[8],[9],[0,\"\\n    Legende:\\n    \"],[7,\"span\",true],[10,\"class\",\"ui grey label\"],[8],[0,\"Entwurf\"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"ui orange label\"],[8],[0,\"Prüfung\"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"ui green label\"],[8],[0,\"Veröffentlicht\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h4\",true],[8],[0,\"Keine Ergebisse gefunden.\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Versuchen Sie es mit anderen Suchkriterien.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/events/calendar/event-list/template.hbs"
    }
  });

  _exports.default = _default;
});