define("webapp/pods/application-error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jeOXTNu4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui container\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"ui header\"],[8],[0,\"Error\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Es ist ein unerwarteter Fehler aufgetreten. Bitte kontaktieren Sie den Support.\"],[9],[0,\"\\n\\n  \"],[7,\"pre\",true],[8],[0,\"    \"],[1,[24,[\"model\",\"stack\"]],false],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[1,[28,\"alpha-fire-action\",null,[[\"onAction\"],[[28,\"action\",[[23,0,[]],\"removeLoadingContainer\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/application-error/template.hbs"
    }
  });

  _exports.default = _default;
});