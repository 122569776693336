define("webapp/pods/components/events/index/selection-actions/cancel/component", ["exports", "webapp/pods/components/events/index/selection-actions/base-modal/component", "webapp/utils/action-model"], function (_exports, _component, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    model: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this.parent, 'cancelModal', this);
    },
    open: function open() {
      var model = _actionModel.default.create();

      Ember.set(model, 'content', {
        cancellationReason: ''
      });
      Ember.set(this, 'model', model);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});