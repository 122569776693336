define("webapp/pods/modules/users/user/reactivate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0RtiYxfD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Reaktivieren\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[0,\"Durch die Reaktivierung wird sich der Benutzer wieder einloggen können.\"],[9],[0,\"\\n\\n\"],[4,\"alpha-task-button\",null,[[\"class\",\"task\"],[\"ui labeled icon green button\",[24,[\"reactivateTask\"]]]],{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"redo icon\"],[8],[9],[0,\" Reaktivieren bestätigen\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/users/user/reactivate/template.hbs"
    }
  });

  _exports.default = _default;
});