define("webapp/pods/components/alpha-fire-action/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.onAction();
    },
    onAction: function onAction() {}
  });

  _exports.default = _default;
});