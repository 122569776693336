define("webapp/pods/modules/events/event/participation-options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UfcGYGBV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",false],[12,\"class\",[29,[\"ui \",[28,\"if\",[[24,[\"update\",\"isRunning\"]],\"loading\"],null],\" form\"]]],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"update\"]],[24,[\"model\"]]],null],[24,[\"model\"]]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Anmelde-Einstellungen\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Bitte Anmeldeschluss auch\\nimmer in der Kurzbeschreibung und in der\\nHauptinformation erwähnen.\"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"fields\"],[8],[0,\"\\n      \"],[1,[28,\"alpha-calendar\",null,[[\"type\",\"label\",\"value\",\"onChange\",\"error\",\"class\"],[\"datetime\",\"Anmeldeschluss\",[24,[\"model\",\"closingDate\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"closingDate\"]]],null]],null],[24,[\"model\",\"errors\",\"closingDate\"]],\"eight wide field\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"fields\"],[8],[0,\"\\n      \"],[1,[28,\"alpha-input\",null,[[\"label\",\"labelAddition\",\"type\",\"value\",\"error\",\"class\"],[\"Max. Teilnehmer\",\"0 = Kein Limit\",\"number\",[24,[\"model\",\"maximum\"]],[24,[\"model\",\"errors\",\"maximum\"]],\"eight wide field\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"ui labeled icon primary button\"],[11,\"disabled\",[29,[[28,\"unless\",[[24,[\"model\",\"hasBufferedChanges\"]],\"disabled\"],null]]]],[10,\"type\",\"submit\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"edit icon\"],[8],[9],[0,\" Speichern\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/events/event/participation-options/template.hbs"
    }
  });

  _exports.default = _default;
});