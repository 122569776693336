define("webapp/pods/components/alpha-input-decimal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    //Attributes
    value: null,
    disabled: null,
    required: null,
    icon: null,
    error: null,
    errorObserver: Ember.observer('error', function () {
      this._checkErrors();
    }),
    valueObserver: Ember.observer('value', function () {
      if (this.value != null) {
        this.set('error', null);
      }
    }),
    didInsertElement: function didInsertElement() {
      this._checkErrors();
    },
    _checkErrors: function _checkErrors() {
      if (this.error) {
        var error = this.error[0];

        if (_typeof(error.message) === 'object') {
          error = error.message;
        }

        this.set('errorMessage', this.intl.t('a.validation.' + error.message, error.parameter));
        this.$().addClass('error');
      } else {
        this.set('errorMessage', null);
        this.$().removeClass('error');
      }
    }
  });

  _exports.default = _default;
});