define("webapp/pods/components/alpha-location-select/map-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oaUc/IjN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"leaflet-map\",null,[[\"lat\",\"lng\",\"zoom\",\"onClick\"],[[24,[\"lat\"]],[24,[\"lng\"]],15,[28,\"action\",[[23,0,[]],\"onClick\"],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"tile-layer\",null,[[\"url\",\"attribution\"],[\"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png\",\"&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>\"]]],false],[0,\"\\n\\n\"],[4,\"marker-layer\",null,[[\"lat\",\"lng\",\"draggable\",\"onDragend\"],[[24,[\"lat\"]],[24,[\"lng\"]],true,[28,\"action\",[[23,0,[]],\"onMarkerDragend\"],null]]],{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/alpha-location-select/map-select/template.hbs"
    }
  });

  _exports.default = _default;
});