define("webapp/router", ["exports", "webapp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('auth', function () {
      this.route('login');
      this.route('logout');
      this.route('activate');
    });
    this.route('modules', function () {
      this.route('events', function () {
        this.route('create');
        this.route('event', {
          path: '/:event_item_id'
        }, function () {
          this.route('delete');
          this.route('publish');
          this.route('cancel');
          this.route('description');
          this.route('files');
          this.route('copy');
          this.route('prices');
          this.route('tour-locations');
          this.route('images');
          this.route('tour-attributes');
          this.route('participants');
          this.route('participation-options');
          this.route('template');
          this.route('message-participants');
          this.route('finish', function () {
            this.route('prepare-tour');
            this.route('final');
            this.route('final-invoice');
          });
          this.route('series');
          this.route('documents', function () {
            this.route('invoice');
            this.route('participant-list');
          });
          this.route('tour-difficulties');
          this.route('changelog');
        });
        this.route('calendar');
        this.route('templates');
        this.route('units', function () {
          this.route('unit', function () {});
        });
        this.route('statistics');
      });
      this.route('users', function () {
        this.route('create');
        this.route('user', {
          path: '/:user_id'
        }, function () {
          this.route('delete');
          this.route('units');
          this.route('email');
          this.route('history');
          this.route('deactivate');
          this.route('reactivate');
          this.route('feedback-email');
          this.route('data-protection');
        });
        this.route('profile', function () {
          this.route('email');
          this.route('units');
          this.route('feedback-email');
        });
      });
      this.route('units', function () {
        this.route('unit', {
          path: '/:unit_id'
        }, function () {
          this.route('price-lists', function () {
            this.route('price-list', {
              path: '/:unit_price_list_id'
            });
          });
          this.route('unit-locations', function () {});
          this.route('invoice-data');
          this.route('images');
        });
      });
      this.route('changelog');
    });
    this.route('users', function () {
      this.route('profile', function () {});
      this.route('user', function () {});
    });
  });
  var _default = Router;
  _exports.default = _default;
});