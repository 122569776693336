define("webapp/pods/modules/events/event/tour-locations/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    tourLocationTypesList: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return Ember.RSVP.all([this.tourLocationTypesList]);
    },
    model: function model() {
      return this.modelFor('modules.events.event');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var tourLocationModel = _actionModel.default.create();

      tourLocationModel.set('content', {
        name: '',
        type: 'Startpunkt',
        beginning: model.get('beginning'),
        end: null,
        withoutTime: false,
        unitKey: model.get('unitKey'),
        city: '',
        zipCode: '',
        street: '',
        longitude: null,
        latitude: null
      }); //tourLocationModel.acceptBufferedChanges();

      Ember.set(controller, 'tourLocationModel', tourLocationModel);
    }
  });

  _exports.default = _default;
});