define("webapp/pods/modules/events/event/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('event-item', params.event_item_id, {
        reload: true
      });
    }
  });

  _exports.default = _default;
});