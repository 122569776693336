define("webapp/pods/components/cron-input/weekly/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cron: null,
    disabled: false,
    _weekDays: Ember.computed('cron', function () {
      var self = this;
      return [{
        value: 'MON',
        name: 'Montag'
      }, {
        value: 'TUE',
        name: 'Dienstag'
      }, {
        value: 'WED',
        name: 'Mittwoch'
      }, {
        value: 'THU',
        name: 'Donnerstag'
      }, {
        value: 'FRI',
        name: 'Freitag'
      }, {
        value: 'SAT',
        name: 'Samstag'
      }, {
        value: 'SUN',
        name: 'Sonntag'
      }].map(function (item) {
        item.checked = self._isChecked(item.value);
        return item;
      });
    }),
    _isChecked: function _isChecked(value) {
      var weekDay = Ember.get(this, 'cron.weekDay');
      return weekDay.includes(value);
    },
    onChange: function onChange(cron) {},
    actions: {
      onChange: function onChange(changedItem) {
        // toggle checked on changed item
        var days = this._weekDays.map(function (item) {
          if (changedItem.value != item.value) {
            return item;
          } else {
            Ember.set(item, 'checked', !item.checked);
            return item;
          }
        });

        var joinedWeekDays = days.filter(function (item) {
          return item.checked;
        }).map(function (item) {
          return item.value;
        }).join(','); // if last unselected, pick another to prevent empty weekDay

        if (joinedWeekDays == '') {
          joinedWeekDays = days.find(function (item) {
            return item.value != changedItem.value;
          }).value;
        }

        var action = this.onChange;
        action("0 0 ? * ".concat(joinedWeekDays));
      }
    }
  });

  _exports.default = _default;
});