define("webapp/pods/components/units/index-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YF+LhKxr",
    "block": "{\"symbols\":[\"unit\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"ui selectable celled striped table\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Name\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"key\",[24,[\"model\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",false],[3,\"action\",[[23,0,[]],\"rowClick\",[23,1,[]]]],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"key\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[10,\"colspan\",\"2\"],[8],[0,\"Keine Gliederungen gefunden\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/units/index-table/template.hbs"
    }
  });

  _exports.default = _default;
});