define("webapp/models/user-unit", ["exports", "alpha-data/models/user-unit", "@ember-data/model"], function (_exports, _userUnit, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import DS from 'ember-data';
  var _default = _userUnit.default.extend({
    cIsGenerated: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});