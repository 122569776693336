define("webapp/services/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    show: function show(text) {
      var self = this;
      var promise = new Ember.RSVP.Promise(function (resolve) {
        self.one('closed', self, resolve);
      });
      this.trigger('showModal', {
        text: text
      });
      return promise;
    }
  });

  _exports.default = _default;
});