define("webapp/models/event-item-image", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    image: (0, _model.belongsTo)('image', {
      async: false
    }),
    eventItem: (0, _model.belongsTo)('event-item', {
      async: false
    }),
    hasCopyright: Ember.computed('image.copyright', function () {
      return Ember.get(this, 'image.copyright') ? true : false;
    })
  });

  _exports.default = _default;
});