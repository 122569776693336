define("webapp/pods/components/cron-input/daily/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cron: null,
    disabled: false,
    _selected: Ember.computed('cron', function () {
      return Ember.get(this, 'cron.day').split('/')[1];
    }),
    _days: Ember.computed('cron', function () {
      var days = [];

      for (var i = 1; i < 32; i++) {
        days.push({
          name: i + '.',
          value: i
        });
      }

      return days;
    }),
    onChange: function onChange(cron) {},
    actions: {
      onChange: function onChange(day) {
        var action = this.onChange;
        action("0 0 1/".concat(day, " * *"));
      }
    }
  });

  _exports.default = _default;
});