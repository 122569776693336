define("webapp/pods/modules/users/user/deactivate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wL9mRVP/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Deaktivieren\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[0,\"Deaktivierte Benutzer können sich nicht mehr einloggen. Eine Reaktivierung ist jederzeit möglich.\"],[9],[0,\"\\n\\n\"],[4,\"alpha-task-button\",null,[[\"class\",\"task\"],[\"ui labeled icon red button\",[24,[\"deactivateTask\"]]]],{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"ban icon\"],[8],[9],[0,\" Deaktivieren bestätigen\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/users/user/deactivate/template.hbs"
    }
  });

  _exports.default = _default;
});