define("webapp/pods/modules/users/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    salutationsList: Ember.inject.service(),
    unitsList: Ember.inject.service(),
    rolesList: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return Ember.RSVP.all([this.salutationsList, this.unitsList, this.rolesList]);
    }
  });

  _exports.default = _default;
});