define("webapp/pods/modules/events/create/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    unitsList: Ember.inject.service(),
    model: function model() {
      var model = _actionModel.default.create();

      model.set('content', {
        title: '',
        beginning: null,
        end: null,
        unitKey: Ember.get(this, 'unitsList.content.firstObject.key'),
        cShortDescription: '',
        city: '',
        zipCode: '',
        street: '',
        longitude: null,
        latitude: null,
        cSecondOrganizingUserId: null,
        cWithoutTime: false,
        cRegistrationType: 'None',
        cExternalRegistrationUrl: '',
        cExternalEvent: false
      });
      model.applyBufferedChanges();
      return model;
    }
  });

  _exports.default = _default;
});