define("webapp/pods/components/alpha-textarea/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    //Attributes
    value: null,
    disabled: null,
    error: null,
    maxlength: null,
    labelAddition: '',
    didInsertElement: function didInsertElement() {
      this._checkErrors();

      this._calculateCharsLeft();
    },
    errorObserver: Ember.observer('error', function () {
      this._checkErrors();
    }),
    valueObserver: Ember.observer('value', function () {
      if (this.value != null) {
        this.set('error', null);
      }

      this._calculateCharsLeft();
    }),
    _calculateCharsLeft: function _calculateCharsLeft() {
      if (this.maxlength != null) {
        this.set('showChars', true);
        var valueLength = this.get('value.length') ? this.get('value.length') : 0;
        this.set('charsLeft', this.maxlength - valueLength);
      }
    },
    _checkErrors: function _checkErrors() {
      if (this.error) {
        var error = this.error[0];

        if (_typeof(error.message) === 'object') {
          error = error.message;
        }

        this.set('errorMessage', this.intl.t('a.validation.' + error.message, error.parameter));
        this.$().addClass('error');
      } else {
        this.set('errorMessage', null);
        this.$().removeClass('error');
      }
    },
    focusOut: function focusOut() {},
    keyUp: function keyUp() {}
  });

  _exports.default = _default;
});