define("webapp/pods/modules/events/event/cancel/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var model = _actionModel.default.create();

      Ember.set(model, 'content', {
        cancellationReason: ''
      });
      return model;
    },
    actions: {
      delete: function _delete(model) {
        var self = this;
        var eventItem = this.modelFor('modules.events.event');
        var result = model.getContent();
        eventItem.cancel(result).then(function (response) {
          self.send('handleSuccess');
          self.store.pushPayload('event-item', response);
          self.transitionTo('modules.events.event', eventItem);
        }).catch(function (error) {
          model.setError(error);
          self.send('handleError', error);
        }).finally(function () {});
      }
    }
  });

  _exports.default = _default;
});