define("webapp/pods/modules/users/profile/units/route", ["exports", "webapp/mixins/routes/dirty-confirm"], function (_exports, _dirtyConfirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dirtyConfirm.default, {
    currentUser: Ember.inject.service(),
    model: function model() {
      var user = Ember.get(this, 'currentUser.model');
      return this.store.findRecord('user', user.id);
    }
  });

  _exports.default = _default;
});