define("webapp/pods/components/alpha-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui input'],
    selector: null,
    label: false,
    type: '',
    checked: false,
    disabled: false,
    internalChange: false,
    checkedChanged: Ember.observer('checked', function () {
      if (this.internalChange) {
        this.set('internalChange', false);
        return;
      }

      this.set('internalChange', false);
      var selector = this.selector;

      if (this.checked) {
        selector.checkbox('set checked');
      } else {
        selector.checkbox('set unchecked');
      }
    }),
    didInsertElement: function didInsertElement() {
      var self = this;
      var selector = this.$('.checkbox').checkbox({
        onChecked: function onChecked() {
          var action = self.get('onchange');
          self.set('internalChange', true);
          action(true);
        },
        onUnchecked: function onUnchecked() {
          var action = self.get('onchange');
          self.set('internalChange', true);
          action(false);
        }
      });

      if (this.checked) {
        selector.checkbox('set checked');
      }

      this.set('selector', selector);
    }
  });

  _exports.default = _default;
});