define("webapp/pods/modules/units/unit/invoice-data/route", ["exports", "webapp/utils/action-model", "webapp/mixins/routes/dirty-confirm"], function (_exports, _actionModel, _dirtyConfirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dirtyConfirm.default, {
    model: function model() {
      var unit = this.modelFor('modules.units.unit');

      var model = _actionModel.default.create();

      Ember.set(model, 'content', {
        street: Ember.get(unit, 'street'),
        city: Ember.get(unit, 'city'),
        iban: Ember.get(unit, 'iban'),
        zipCode: Ember.get(unit, 'zipCode'),
        cOrganizerShare: Ember.get(unit, 'cOrganizerShare'),
        eMailAddress: Ember.get(unit, 'eMailAddress'),
        fax: Ember.get(unit, 'fax')
      });
      model.applyBufferedChanges();
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, 'unit', this.modelFor('modules.units.unit'));
    }
  });

  _exports.default = _default;
});