define("webapp/pods/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1CAwG6ua",
    "block": "{\"symbols\":[\"err\"],\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n      Es ist ein Fehler aufgetreten\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"status\"]],403],null]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[0,\"\\n      \"],[7,\"strong\",true],[8],[0,\"403: \"],[9],[0,\"Sie haben nicht das Recht auf diese Seite zuzugreifen.\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Bitte versuchen Sie die Seite neu zu laden.\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"ui primary button\"],[8],[0,\"Neu Laden\"],[9],[0,\"\\n    \"],[1,[28,\"log\",[[24,[\"model\"]]],null],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n        \"],[7,\"h4\",true],[8],[0,\"\\n          \"],[1,[24,[\"model\",\"message\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"errors\"]]],null,{\"statements\":[[0,\"          \"],[7,\"pre\",true],[8],[0,\"        \"],[1,[23,1,[\"title\"]],false],[0,\"          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n        \"],[7,\"pre\",true],[8],[0,\"        \"],[1,[24,[\"model\",\"stack\"]],false],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/error/template.hbs"
    }
  });

  _exports.default = _default;
});