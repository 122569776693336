define("webapp/models/event-participant", ["exports", "alpha-data/models/event-participant", "@ember-data/model"], function (_exports, _eventParticipant, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _eventParticipant.default.extend({
    cIsMember: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});