define("webapp/pods/modules/events/statistics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sPgSGd7t",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui stackable grid\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"calendar icon\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n          Statistiken\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\\n  \"],[7,\"p\",true],[8],[0,\"Hier können Sie Statistiken herunterladen.\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n      \"],[1,[28,\"events/statistics/options-bar\",null,[[\"exportTaskStatisticCsv\",\"exportTaskStatisticPdf\"],[[24,[\"exportTaskStatisticCsv\"]],[24,[\"exportTaskStatisticPdf\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/events/statistics/template.hbs"
    }
  });

  _exports.default = _default;
});