define("webapp/pods/components/alpha-location-select/result-popup/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    result: null,
    classNames: ['ui bottom left fluid popup'],
    didInsertElement: function didInsertElement() {
      var popup = this.$().parent().popup({
        popup: this.$(),
        position: 'bottom left',
        on: 'manual',
        lastResort: 'bottom left'
      });
      popup.popup('show');
      Ember.set(this, '_popup', popup);
    },
    willDestroyElement: function willDestroyElement() {
      this._popup.popup('destroy');
    },
    actions: {
      select: function select(item) {
        var action = this.onSelect;
        action(item);
      }
    }
  });

  _exports.default = _default;
});