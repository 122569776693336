define("webapp/pods/modules/units/unit/unit-locations/index/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var unit = this.modelFor('modules.units.unit');
      return Ember.get(unit, 'unitLocations');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var unitLocationModel = _actionModel.default.create();

      Ember.set(unitLocationModel, 'content', {
        street: '',
        city: '',
        zipCode: '',
        latitude: null,
        longitude: null
      });
      Ember.set(controller, 'unitLocationModel', unitLocationModel);
      Ember.set(controller, 'unit', this.modelFor('modules.units.unit'));
    }
  });

  _exports.default = _default;
});