define("webapp/pods/components/module-selection/query-builder/rule/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row'],
    rule: null,
    fields: null,
    selectableFields: null,
    _type: null,
    _options: null,
    _errors: Ember.computed('rule.{operator,operand,field}', '_operator.values', function () {
      var errors = null;

      if (Ember.get(this, 'rule.operator') && Ember.get(this, '_operator.values') == 1) {
        if (Ember.get(this, 'rule.operand') == null || Ember.get(this, 'rule.operand') === '') {
          errors = [{
            message: 'notEmpty',
            parameter: {}
          }];
        }
      }

      return errors;
    }),
    _fields: Ember.computed('fields', 'selectableFields', function () {
      var fields = this.fields;
      var selectable = this.selectableFields;
      return selectable.map(function (item) {
        var original = fields.find(function (orig) {
          return orig.id == item.id;
        });
        original.name = item.name;

        if (item.type) {
          original.type = item.type;
          original.options = item.options;
        }

        return original;
      });
    }),
    _operator: Ember.computed('rule.operator', function () {
      return this._operators.findBy('key', this.get('rule.operator'));
    }),
    _ruleOperators: Ember.computed('rule.field', function () {
      var self = this;
      var field = Ember.get(this, 'rule.field');

      if (!field) {
        return;
      }

      var searchField = this.fields.findBy('id', field);
      var type = Ember.get(searchField, 'type');
      var options = Ember.get(searchField, 'options');
      Ember.set(this, '_type', type);
      Ember.set(this, '_options', options);

      var operators = this._operators.filter(function (operator) {
        var types = Ember.A(operator.types);
        return types.includes(type);
      });

      if (!operators.findBy('key', Ember.get(self, 'rule.operator'))) {
        Ember.set(self, 'rule.operator', operators[0].key);
      }

      return operators;
    }),
    _operators: [{
      label: 'Gleich',
      key: 'equals',
      types: ['string', 'number', 'select'],
      values: 1
    }, {
      label: 'Ungleich',
      key: 'notEquals',
      types: ['string', 'number', 'select'],
      values: 1
    }, {
      label: 'Leer',
      key: 'empty',
      types: ['string', 'number', 'date'],
      values: 0
    }, {
      label: 'Nicht leer',
      key: 'notEmpty',
      types: ['string', 'number', 'date'],
      values: 0
    }, {
      label: 'Enthält',
      key: 'contains',
      types: ['string'],
      values: 1
    }, {
      label: 'Enthält nicht',
      key: 'containsNot',
      types: ['string'],
      values: 1
    }, {
      label: 'Größer als',
      key: 'greater',
      types: ['number', 'date'],
      values: 1
    }, {
      label: 'Kleiner als',
      key: 'less',
      types: ['number', 'date'],
      values: 1
    }, {
      label: 'Ja',
      key: 'yes',
      types: ['boolean'],
      values: 0
    }, {
      label: 'Nein',
      key: 'no',
      types: ['boolean'],
      values: 0
    }],
    actions: {
      fieldChanged: function fieldChanged() {
        Ember.set(this, 'rule.operand', null);
      }
    }
  });

  _exports.default = _default;
});