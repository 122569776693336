define("webapp/pods/modules/events/event/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gylrb8k0",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"form-event\",null,[[\"model\",\"eventItem\",\"updating\",\"task\"],[[24,[\"model\"]],[24,[\"eventItem\"]],true,[24,[\"save\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/events/event/index/template.hbs"
    }
  });

  _exports.default = _default;
});