define("webapp/initializers/nprogress", ["exports", "ember-cli-nprogress/initializers/nprogress"], function (_exports, _nprogress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _nprogress.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _nprogress.initialize;
    }
  });
});