define("webapp/pods/components/events/event/tour-attributes/type-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hIkHMUwE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"labelAddition\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"(erforderlich, \"],[1,[22,\"labelAddition\"],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"(erforderlich)\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"labelAddition\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"(\"],[1,[22,\"labelAddition\"],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui fluid multiple search normal selection dropdown\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/events/event/tour-attributes/type-select/template.hbs"
    }
  });

  _exports.default = _default;
});