define("webapp/pods/components/adfc-location/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    unitId: null,
    isDisabled: false,
    _modelObserver: Ember.observer('model.{city,zipCode,street,latitude,longitude}', function () {
      this._updateSelected();
    }),
    _errorObserver: Ember.observer('model.errors.{city,zipCode,street,latitude,longitude}', function () {
      var errors = [];
      var city = Ember.get(this, 'model.errors.city');
      var zipCode = Ember.get(this, 'model.errors.zipCode');
      var street = Ember.get(this, 'model.errors.street');

      if (city) {
        errors = errors.concat(city);
      }

      if (zipCode) {
        errors = errors.concat(zipCode);
      }

      if (street) {
        errors = errors.concat(street);
      }

      Ember.set(this, 'error', errors);
    }),
    didInsertElement: function didInsertElement() {
      this._updateSelected();
    },
    actions: {
      onSelected: function onSelected(item) {
        if (item == null) {
          item = Ember.Object.create();
        }

        var city = Ember.get(item, 'city');

        if (city) {
          if (city.includes("undefined, ")) {
            city = city.replace("undefined, ", "");
          }

          if (city.includes("undefined")) {
            city = city.replace("undefined", "");
          }
        }

        Ember.set(this, 'model.city', city);
        Ember.set(this, 'model.zipCode', Ember.get(item, 'zipCode'));
        Ember.set(this, 'model.street', Ember.get(item, 'street'));
        Ember.set(this, 'model.latitude', Ember.get(item, 'latitude'));
        Ember.set(this, 'model.longitude', Ember.get(item, 'longitude'));
      }
    },
    _updateSelected: function _updateSelected() {
      var model = this.model;
      var selected = {
        city: Ember.get(model, 'city'),
        zipCode: Ember.get(model, 'zipCode'),
        street: Ember.get(model, 'street'),
        latitude: Ember.get(model, 'latitude'),
        longitude: Ember.get(model, 'longitude')
      };
      Ember.set(this, 'selected', selected);
    }
  });

  _exports.default = _default;
});