define("webapp/pods/modules/users/profile/units/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OxIyt+Bj",
    "block": "{\"symbols\":[\"userUnit\",\"userUnitRole\"],\"statements\":[[0,\"\\n\\n\"],[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Gliederungen und Rollen\"],[9],[0,\"\\n\\n\"],[4,\"editor-notes\",null,null,{\"statements\":[[0,\"  Hier können Sie sich die Berechtigungen für Ihr Profil ansehen.\\n  Wenden Sie sich an Ihren Administrator, um Ihre Berechtigungen zu ändern oder neue hinzuzufügen.\\n  Administratoren können Berechtigungen bearbeiten (Menüpunkt Benutzer auswählen – einen Benutzer aufrufen – Berechtigungen hinzufügen/ändern).\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"table\",true],[10,\"class\",\"ui celled striped table\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Gliederung\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Rolle\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"userUnits\"]]],null,{\"statements\":[[4,\"if\",[[28,\"and\",[[23,1,[]],[28,\"not\",[[23,1,[\"cIsGenerated\"]]],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"td\",true],[8],[1,[23,1,[\"unit\",\"name\"]],false],[9],[0,\"\\n          \"],[7,\"td\",true],[8],[0,\"\\n            \"],[4,\"each\",[[23,1,[\"userUnitRoles\"]]],null,{\"statements\":[[0,\" \"],[1,[23,2,[\"role\",\"name\"]],false],[0,\" \"]],\"parameters\":[2]},null],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/users/profile/units/template.hbs"
    }
  });

  _exports.default = _default;
});