define("webapp/pods/components/events/event/participants/add-participant-form/component", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addParticipantTask: null,
    didInsertElement: function didInsertElement() {
      var addParticipantModel = _actionModel.default.create();

      addParticipantModel.set('content', {
        firstName: '',
        lastName: '',
        eMailAddress: '',
        isMember: false,
        phone: '',
        street: '',
        zipCode: '',
        city: ''
      });
      Ember.set(this, 'addParticipantModel', addParticipantModel);
    }
  });

  _exports.default = _default;
});