define("webapp/pods/application-error/route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      removeLoadingContainer: function removeLoadingContainer() {
        var focused = (0, _jquery.default)(':focus');
        (0, _jquery.default)('.loading-container').transition('scale', 500, function () {
          (0, _jquery.default)('.loading-container').remove();
          focused.focus();
        });
      }
    }
  });

  _exports.default = _default;
});