define("webapp/pods/modules/events/event/finish/final/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w27BwCva",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n\"],[4,\"alpha-task-button\",null,[[\"class\",\"task\"],[\"ui primary labeled icon button\",[24,[\"finishTask\"]]]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"lock icon\"],[8],[9],[0,\" Veranstaltung abschließen\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n    \"],[1,[28,\"events/event/download-tour-invoice\",null,[[\"eventItem\"],[[24,[\"model\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/events/event/finish/final/template.hbs"
    }
  });

  _exports.default = _default;
});