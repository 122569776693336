define("webapp/pods/components/events/statistics/options-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    unitsList: Ember.inject.service(),
    unitKey: null,
    year: null,
    onChange: function onChange() {},
    years: Ember.A(),
    init: function init() {
      this._super.apply(this, arguments);

      this.setYears();
      Ember.set(this, 'unitKey', Ember.get(this, 'unitsList.content.firstObject.key'));
    },
    actions: {
      changed: function changed() {
        var action = this.onChange;
        action(Ember.getProperties(this, 'unitKey'));
      }
    },
    setYears: function setYears() {
      var years = this.years;
      var actualYear = new Date().getFullYear();
      years.clear();

      for (var i = 2017; i <= actualYear; i++) {
        years.pushObject({
          value: i
        });
      }

      Ember.set(this, 'year', new Date().getFullYear());
    }
  });

  _exports.default = _default;
});