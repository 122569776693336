define("webapp/pods/components/alpha-location-select/result-popup/result-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OsERIaGW",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[24,[\"result\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"alpha-location-select/result-popup/result-item\",null,[[\"item\",\"active\",\"onSelect\"],[[23,1,[]],[28,\"eq\",[[23,1,[]],[24,[\"activeItem\"]]],null],[28,\"action\",[[23,0,[]],\"select\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"idle item\"],[8],[0,\"\\n    Adresse nicht gefunden. Beginnen Sie mit einem Straßennamen.\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/alpha-location-select/result-popup/result-list/template.hbs"
    }
  });

  _exports.default = _default;
});