define("webapp/pods/components/cron-input/daily/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H0rSEnF6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui horizontal list\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n    Jeden\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n    \"],[1,[28,\"alpha-select\",null,[[\"options\",\"selected\",\"fluid\",\"displayField\",\"valueField\",\"onChange\",\"disabled\"],[[24,[\"_days\"]],[24,[\"_selected\"]],false,\"name\",\"value\",[28,\"action\",[[23,0,[]],\"onChange\"],null],[24,[\"disabled\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n    Tag\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/cron-input/daily/template.hbs"
    }
  });

  _exports.default = _default;
});