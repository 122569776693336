define("webapp/pods/modules/units/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    actions: {
      transitionToUnit: function transitionToUnit(model) {
        this.router.transitionTo('modules.units.unit.index', model);
      }
    }
  });

  _exports.default = _default;
});