define("webapp/pods/components/alpha-location-select/result-popup/result-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onSelect: function onSelect() {},
    result: null,
    activeItem: null,
    actions: {
      select: function select(item) {
        var action = this.onSelect;
        action(item);
      }
    },
    didInsertElement: function didInsertElement() {
      if (!Ember.get(this, 'result.length')) {
        return;
      }

      Ember.set(this, 'activeItem', Ember.get(this, 'result.firstObject'));
      Ember.set(this, '_boundKeyPresshandler', Ember.run.bind(this, this._keypressHandler));
      this.$(document).bind('keydown', this._boundKeyPresshandler);
    },
    willDestroyElement: function willDestroyElement() {
      this.$(document).unbind('keydown', this._boundKeyPresshandler);
    },
    _keypressHandler: function _keypressHandler(e) {
      var items = this.result,
          activeItem = this.activeItem,
          currentPosition = items.indexOf(activeItem); //Up

      if (e.keyCode == 38) {
        e.preventDefault();

        if (currentPosition == 0) {
          Ember.set(this, 'activeItem', Ember.get(items, 'lastObject'));
        } else {
          var newPosition = currentPosition - 1;
          Ember.set(this, 'activeItem', items.objectAt(newPosition));
        }
      } // Down
      else if (e.keyCode == 40) {
        e.preventDefault();

        if (currentPosition == Ember.get(items, 'length') - 1) {
          Ember.set(this, 'activeItem', items.objectAt(0));
        } else {
          var _newPosition = currentPosition + 1;

          Ember.set(this, 'activeItem', items.objectAt(_newPosition));
        }
      } // Enter
      else if (e.keyCode == 13) {
        e.preventDefault();
        this.send('select', this.activeItem);
      }
    }
  });

  _exports.default = _default;
});