define("webapp/serializers/event-item", ["exports", "alpha-data/serializers/event-item"], function (_exports, _eventItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _eventItem.default;
    }
  });
});