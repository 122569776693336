define("webapp/pods/modules/events/event/copy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+3fkNFRe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Kopieren\"],[9],[0,\"\\n\"],[4,\"editor-notes\",null,null,{\"statements\":[[0,\"  Veranastaltung kann zur Arbeitserleichterung kopiert werden.\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"Diese Veranstaltung kopieren, um eine neue zu erstellen.\"],[9],[0,\"\\n\"],[7,\"form\",false],[12,\"class\",[29,[\"ui \",[28,\"if\",[[24,[\"copy\",\"isRunning\"]],\"loading\"],null],\" form\"]]],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"copy\"]],[24,[\"model\"]]],null]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[1,[28,\"alpha-checkbox\",null,[[\"checked\",\"label\",\"onchange\"],[[24,[\"model\",\"includePrices\"]],\"Preise kopieren\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"includePrices\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[1,[28,\"alpha-checkbox\",null,[[\"checked\",\"label\",\"onchange\"],[[24,[\"model\",\"includeFiles\"]],\"Bilder und Dateien kopieren\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"includeFiles\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[1,[28,\"alpha-checkbox\",null,[[\"checked\",\"label\",\"onchange\"],[[24,[\"model\",\"includeLocations\"]],\"Orte kopieren\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"includeLocations\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\\n  \"],[7,\"button\",true],[10,\"name\",\"button\"],[10,\"class\",\"ui labeled icon primary button\"],[10,\"type\",\"submit\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"copy icon\"],[8],[9],[0,\" Kopieren\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/events/event/copy/template.hbs"
    }
  });

  _exports.default = _default;
});