define("webapp/services/user-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    setItem: function setItem(name, value) {
      name = 'config.' + name;
      localStorage.setItem(name, value);
    },
    getItem: function getItem(name) {
      name = 'config.' + name;
      return localStorage.getItem(name);
    }
  });

  _exports.default = _default;
});