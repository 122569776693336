define("webapp/pods/components/events/index/selection-actions/base-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    parent: null,
    _selector: null,
    error: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var selector = (0, _jquery.default)("#".concat(this.elementId, " .modal"));
      Ember.set(this, '_selector', selector);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var selector = this._selector;

      if (Ember.isPresent(selector)) {
        selector.modal('destroy');
      }
    },
    open: function open() {
      Ember.set(this, 'error', false);

      this._selector.modal().modal('show');
    },
    close: function close() {
      Ember.set(this, 'error', false);

      this._selector.modal().modal('hide');
    }
  });

  _exports.default = _default;
});