define("webapp/mixins/routes/dirty-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    confirm: Ember.inject.service(),
    dirtyModelPath: null,
    actions: {
      willTransition: function willTransition(transition) {
        var model = this.currentModel;

        if (this.dirtyModelPath) {
          model = model[this.dirtyModelPath];
        }

        if (model.get('hasChanges')) {
          transition.abort();
          this.confirm.show('Ungespeicherte Änderungen gehen verloren. Wirklich abbrechen?').then(function (confirms) {
            if (confirms) {
              model.discardBufferedChanges();
              transition.retry();
            }
          });
          return false;
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});