define("webapp/pods/modules/events/event/publish/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var model = this.modelFor('modules.events.event');
      Ember.set(model, 'content', {
        userId: ''
      });
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var users = this.store.peekAll('publishing-user');
      Ember.set(controller, 'userList', users);
      Ember.set(controller, 'eventItem', this.modelFor('modules.events.event'));
    }
  });

  _exports.default = _default;
});