define("webapp/models/user", ["exports", "alpha-data/models/user", "ember-custom-actions", "@ember-data/model"], function (_exports, _user, _emberCustomActions, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import DS from 'ember-data';
  var _default = _user.default.extend({
    canManageUsers: (0, _model.attr)('boolean'),
    cBio: (0, _model.attr)('string'),
    cPublishOrganizer: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cUnits: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    cTourInvoicePaymentType: (0, _model.attr)('string', {
      defaultValue: 'Transfer'
    }),
    cIsDeactivated: (0, _model.attr)('boolean'),
    cFeedbackEmail: (0, _model.attr)('string'),
    cCreatedBy: (0, _model.attr)('string'),
    cCreatedAt: (0, _model.attr)('date'),
    cTemporaryContact: (0, _model.belongsTo)('temporaryContact'),
    me: (0, _emberCustomActions.resourceAction)('/me', {
      type: 'GET'
    }),
    create: (0, _emberCustomActions.resourceAction)('/', {
      type: 'POST'
    }),
    activate: (0, _emberCustomActions.resourceAction)('activate', {
      type: 'POST'
    }),
    update: (0, _emberCustomActions.modelAction)('/'),
    updateProfile: (0, _emberCustomActions.modelAction)('/updateProfile'),
    updateEmail: (0, _emberCustomActions.modelAction)('updateEmail', {
      type: 'POST'
    }),
    updateFeedbackEmail: (0, _emberCustomActions.modelAction)('updateFeedbackEmail', {
      type: 'POST'
    }),
    deactivateUser: (0, _emberCustomActions.modelAction)('deactivateUser', {
      type: 'PUT'
    }),
    reactivateUser: (0, _emberCustomActions.modelAction)('reactivateUser', {
      type: 'PUT'
    }),
    addUnit: (0, _emberCustomActions.modelAction)('addUnit', {
      type: 'POST'
    }),
    removeUnit: (0, _emberCustomActions.modelAction)('removeUnit', {
      type: 'POST'
    }),
    getHistory: (0, _emberCustomActions.modelAction)('getHistory', {
      type: 'GET'
    }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return "".concat(this.firstName, " ").concat(this.lastName);
    }),
    unloadUnits: function unloadUnits() {
      this.userUnits.forEach(function (item) {
        if (!item) return; // Workaround
        // https://github.com/emberjs/data/issues/5328#issuecomment-360321452

        Ember.run.next(function () {
          Ember.get(item, '_internalModel').transitionTo('deleted.saved');
          item.unloadRecord();
        });
      });
    }
  });

  _exports.default = _default;
});