define("webapp/pods/modules/users/profile/feedback-email/route", ["exports", "webapp/utils/action-model", "webapp/mixins/routes/dirty-confirm"], function (_exports, _actionModel, _dirtyConfirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dirtyConfirm.default, {
    currentUser: Ember.inject.service(),
    model: function model() {
      var user = Ember.get(this, "currentUser.model");

      var model = _actionModel.default.create();

      model.set("content", {
        cFeedbackEmail: ""
      });
      model.fill(user);
      model.applyBufferedChanges();
      return model;
    }
  });

  _exports.default = _default;
});