define("webapp/pods/modules/users/user/data-protection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rfJRjf69",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Datenschutz\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[0,\"\\n  Hier können Sie einsehen, ob der Benutzer schon den Datenschutzrichtlinien zugestimmt hat.\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"alpha-checkbox\",null,[[\"label\",\"checked\",\"disabled\"],[\"Datenschutz zugestimmt\",[24,[\"model\",\"cPublishOrganizer\"]],true]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/users/user/data-protection/template.hbs"
    }
  });

  _exports.default = _default;
});