define("webapp/pods/modules/events/event/description/route", ["exports", "webapp/utils/action-model", "webapp/mixins/routes/dirty-confirm"], function (_exports, _actionModel, _dirtyConfirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dirtyConfirm.default, {
    model: function model() {
      var eventItem = this.modelFor('modules.events.event');

      var model = _actionModel.default.create();

      model.set('content', {
        description: ''
      });
      model.fill(eventItem);
      model.applyBufferedChanges();
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, 'eventItem', this.modelFor('modules.events.event'));
    }
  });

  _exports.default = _default;
});