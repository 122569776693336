define("webapp/pods/components/alpha-input-decimal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/YcKND04",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"labelAddition\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"(erforderlich, \"],[1,[22,\"labelAddition\"],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"(erforderlich)\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"labelAddition\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"(\"],[1,[22,\"labelAddition\"],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"ui \",[28,\"if\",[[24,[\"icon\"]],\"left icon\"],null],\" \",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null],\" \",[28,\"if\",[[24,[\"fluid\"]],\"fluid\"],null],\" input \",[28,\"if\",[[24,[\"error\"]],\"error\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[[22,\"icon\"],\" icon\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"number-input\",null,[[\"disabled\",\"placeholder\",\"unmaskedValue\",\"decimal\",\"radix\",\"unmaskAsNumber\"],[[24,[\"disabled\"]],[24,[\"placeholder\"]],[24,[\"value\"]],true,\",\",true]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"class\",\"ui input error\"],[8],[1,[22,\"errorMessage\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/alpha-input-decimal/template.hbs"
    }
  });

  _exports.default = _default;
});