define("webapp/pods/modules/units/unit/price-lists/index/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var unit = this.modelFor("modules.units.unit");
      return Ember.get(unit, "unitPriceLists");
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var priceListModel = _actionModel.default.create();

      Ember.set(priceListModel, "content", {
        name: ""
      });
      Ember.set(controller, "priceListModel", priceListModel);
      Ember.set(controller, "unit", this.modelFor("modules.units.unit"));
    }
  });

  _exports.default = _default;
});