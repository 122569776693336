define("webapp/pods/components/alpha-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y7OvY6pb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"ui \",[22,\"type\"],\" \",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null],\" checkbox\"]]],[8],[0,\"\\n    \"],[7,\"input\",true],[10,\"name\",\"example\"],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n    \"],[7,\"label\",true],[8],[14,1],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"ui \",[22,\"type\"],\" \",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null],\" checkbox\"]]],[8],[0,\"\\n    \"],[7,\"input\",true],[10,\"name\",\"example\"],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"      \"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/alpha-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});