define("webapp/pods/modules/units/unit/price-lists/price-list/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.peekRecord('unit-price-list', params.unit_price_list_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var priceModel = _actionModel.default.create();

      Ember.set(priceModel, 'content', {
        name: '',
        price: '0',
        memberPrice: false,
        tourGuidePercent: 0
      });
      Ember.set(controller, 'priceModel', priceModel);
      Ember.set(controller, 'priceList', this.modelFor('modules.units.unit.price-lists.price-list'));
    }
  });

  _exports.default = _default;
});