define("webapp/pods/modules/events/event/finish/final-invoice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WyaoS8Tt",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n  \"],[7,\"h5\",true],[10,\"class\",\"ui header\"],[8],[0,\"Abrechungsentwurf\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Bitte prüfen Sie vor dem Abschluss die Abrechnung.\"],[9],[0,\"\\n  \"],[1,[28,\"events/event/download-tour-invoice\",null,[[\"eventItem\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\\n\"],[4,\"alpha-task-button\",null,[[\"class\",\"task\"],[\"ui labeled icon button\",[24,[\"backTask\"]]]],{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"reply icon\"],[8],[9],[0,\" Zurück\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"alpha-task-button\",null,[[\"class\",\"task\"],[\"ui primary labeled icon button\",[24,[\"finishTask\"]]]],{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"lock icon\"],[8],[9],[0,\" Veranstaltung abschließen\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/events/event/finish/final-invoice/template.hbs"
    }
  });

  _exports.default = _default;
});