define("webapp/pods/modules/users/profile/email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "islVdVmt",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\"],[7,\"form\",false],[12,\"class\",[29,[\"ui \",[28,\"if\",[[24,[\"save\",\"isRunning\"]],\"loading\"],null],\" form\"]]],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"save\"]],[24,[\"model\"]]],null]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\\n  \"],[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"E-Mail Adresse ändern\"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"fields\"],[8],[0,\"\\n      \"],[1,[28,\"alpha-input\",null,[[\"label\",\"value\",\"required\",\"error\",\"focused\",\"class\"],[\"E-Mail-Adresse\",[24,[\"model\",\"eMailAddress\"]],true,[24,[\"model\",\"errors\",\"eMailAddress\"]],true,\"sixteen wide field\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"ui labeled icon primary button\"],[11,\"disabled\",[29,[[28,\"unless\",[[24,[\"model\",\"hasBufferedChanges\"]],\"disabled\"],null]]]],[10,\"type\",\"submit\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"edit icon\"],[8],[9],[0,\" Speichern\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/users/profile/email/template.hbs"
    }
  });

  _exports.default = _default;
});