define("webapp/pods/modules/events/event/finish/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var eventItem = this.modelFor('modules.events.event');

      if (Ember.get(eventItem, 'isTour') && Ember.get(eventItem, 'cRegistrationType') != 'External') {
        return this.transitionTo('modules.events.event.finish.prepare-tour');
      } else {
        return this.transitionTo('modules.events.event.finish.final');
      }
    }
  });

  _exports.default = _default;
});