define("webapp/pods/components/alpha-accordion/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui accordion'],
    selector: null,
    open: false,
    onOpening: function onOpening() {},
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.set(this, 'selector', this.$().accordion({
        onOpening: function onOpening() {
          var action = Ember.get(self, 'onOpening');
          action();
        }
      }));

      if (this.open) {
        this.$().accordion('open', 0);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.selector.accordion('destroy');
    }
  });

  _exports.default = _default;
});