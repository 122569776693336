define("webapp/pods/components/adfc-location/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r5g+zp8J",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"required\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"labelAddition\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"(erforderlich, \"],[1,[22,\"labelAddition\"],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"(erforderlich)\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"labelAddition\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[0,\"(\"],[1,[22,\"labelAddition\"],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"alpha-location-select\",null,[[\"selected\",\"onSelected\",\"countrycodes\",\"error\",\"unitId\",\"isDisabled\"],[[24,[\"selected\"]],[28,\"action\",[[23,0,[]],\"onSelected\"],null],[24,[\"countrycodes\"]],[24,[\"error\"]],[24,[\"unitId\"]],[24,[\"isDisabled\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/adfc-location/template.hbs"
    }
  });

  _exports.default = _default;
});