define("webapp/models/event-item-price", ["exports", "alpha-data/models/event-item-price", "ember-custom-actions", "@ember-data/model"], function (_exports, _eventItemPrice, _emberCustomActions, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _eventItemPrice.default.extend({
    cMemberPrice: (0, _model.attr)('boolean'),
    cTourGuidePercent: (0, _model.attr)('number'),
    update: (0, _emberCustomActions.modelAction)('/'),
    displayField: Ember.computed('groupName', 'price', function () {
      return "".concat(this.price, "\u20AC - ").concat(this.groupName);
    })
  });

  _exports.default = _default;
});