define("webapp/pods/modules/users/profile/index/route", ["exports", "webapp/utils/action-model", "webapp/mixins/routes/dirty-confirm"], function (_exports, _actionModel, _dirtyConfirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dirtyConfirm.default, {
    currentUser: Ember.inject.service(),
    model: function model() {
      var user = Ember.get(this, "currentUser.model");
      var tempContact = Ember.get(this, "currentUser.model.cTemporaryContact");

      var model = _actionModel.default.create();

      Ember.set(model, "content", {
        cBio: Ember.get(user, "cBio"),
        phone: Ember.get(tempContact, "phone"),
        salutation: Ember.get(tempContact, "salutation"),
        title: Ember.get(tempContact, "title"),
        firstName: Ember.get(tempContact, "firstName"),
        lastName: Ember.get(tempContact, "lastName"),
        street: Ember.get(tempContact, "street"),
        zipCode: Ember.get(tempContact, "zipCode"),
        city: Ember.get(tempContact, "city"),
        cPublishOrganizer: Ember.get(user, "cPublishOrganizer"),
        cTourInvoicePaymentType: Ember.get(user, "cTourInvoicePaymentType")
      });
      model.applyBufferedChanges();
      return model;
    }
  });

  _exports.default = _default;
});