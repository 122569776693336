define("webapp/pods/auth/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'session.isAuthenticated')) {
        return this.transitionTo('modules.events');
      }
    }
  });

  _exports.default = _default;
});