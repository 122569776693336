define("webapp/pods/components/alpha-location-select/result-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DuboJoZq",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui vertical fluid menu\"],[8],[0,\"\\n    \"],[1,[28,\"alpha-location-select/result-popup/result-list\",null,[[\"result\",\"onSelect\"],[[24,[\"result\"]],[28,\"action\",[[23,0,[]],\"select\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/alpha-location-select/result-popup/template.hbs"
    }
  });

  _exports.default = _default;
});