define("webapp/pods/modules/users/user/history/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var user = this.modelFor('modules.users.user');
      return user.getHistory({
        id: user.id
      });
    }
  });

  _exports.default = _default;
});