define("webapp/utils/action-model", ["exports", "ember-buffered-proxy/proxy"], function (_exports, _proxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _proxy.default.extend({
    init: function init() {
      this.properties = false;
      Ember.set(this, 'errors', {});

      this._super.apply(this, arguments);
    },
    errors: null,
    editable: true,
    onStateChange: Ember.observer('editable', 'hasBufferedChanges', function () {
      if (!this.editable && this.hasBufferedChanges) {
        this.discardBufferedChanges();
      }
    }),
    getContent: function getContent() {
      var self = this;
      var model = {};
      Object.keys(this.content).forEach(function (key) {
        Ember.set(model, key, Ember.get(self, 'content')[key]);
      });
      Object.keys(this.buffer).forEach(function (key) {
        Ember.set(model, key, Ember.get(self, 'buffer')[key]);
      });
      return model;
    },
    fill: function fill(model) {
      var content = this.content;
      Object.keys(this.content).forEach(function (key) {
        var modelContent = Ember.get(model, key);

        if (modelContent === undefined) {
          return;
        }

        Ember.set(content, key, Ember.get(model, key));
      });
      return model;
    },
    setError: function setError(error) {
      if (!error || !error.errors || !error.errors.length) {
        Ember.set(this, 'errors', {});
        return;
      }

      var errors = {};
      error.errors.forEach(function (item) {
        if (!item.source) {
          return;
        }

        var key = item.source.pointer.split('/').pop();

        if (errors[key]) {
          errors[key].push(item.detail);
        } else {
          errors[key] = [item.detail];
        }
      });
      Ember.set(this, 'errors', errors);
    }
  });

  _exports.default = _default;
});