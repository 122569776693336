define("webapp/initializers/component-styles", ["exports", "ember-component-css/pod-names", "webapp/mixins/style-namespacing-extras"], function (_exports, _podNames, _styleNamespacingExtras) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var Component = Ember.Component,
      ComponentLookup = Ember.ComponentLookup,
      computed = Ember.computed,
      getOwner = Ember.getOwner;
  ComponentLookup.reopen({
    componentFor: function componentFor(name, owner) {
      owner = owner.hasRegistration ? owner : getOwner(this);

      if (_podNames.default[name] && !owner.hasRegistration("component:".concat(name))) {
        owner.register("component:".concat(name), Component);
      }

      return this._super.apply(this, arguments);
    }
  });
  Component.reopen(_styleNamespacingExtras.default, {
    componentCssClassName: computed({
      get: function get() {
        return _podNames.default[this.get('_componentIdentifier')] || '';
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('_shouldAddNamespacedClassName')) {
        this.classNames = this.classNames.concat(this.get('componentCssClassName'));
      }
    }
  });

  function initialize() {}

  var _default = {
    name: 'component-styles',
    initialize: initialize
  };
  _exports.default = _default;
});