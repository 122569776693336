define("webapp/pods/modules/events/event/prices/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      return this.store.findAll('unit-price-list');
    },
    model: function model() {
      var model = this.modelFor('modules.events.event');
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var priceModel = _actionModel.default.create();

      Ember.set(priceModel, 'content', {
        groupName: '',
        price: '0',
        cMemberPrice: false,
        cTourGuidePercent: '0'
      });
      Ember.set(controller, 'priceModel', priceModel); // Preislisten der Gliederung

      var eventItem = this.modelFor('modules.events.event');
      var unit = this.store.peekAll('unit').findBy('key', Ember.get(eventItem, 'unitKey'));
      Ember.set(controller, 'unitPriceLists', Ember.get(unit, 'unitPriceLists'));
    }
  });

  _exports.default = _default;
});