define("webapp/models/role", ["exports", "alpha-data/models/role", "@ember-data/model"], function (_exports, _role, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _role.default.extend({
    rolePermissions: (0, _model.hasMany)('role-permission', {
      async: false
    })
  });

  _exports.default = _default;
});