define("webapp/pods/components/alpha-location-select/map-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selected: null,
    lat: 50,
    lng: 7,
    didInsertElement: function didInsertElement() {
      this._updateMap();
    },
    _positionObserver: Ember.observer('selected.{latitude,longitude}', function () {
      this._updateMap();
    }),
    _updateMap: function _updateMap() {
      var self = this;

      if (Ember.get(this, 'selected.latitude')) {
        Ember.set(this, 'lat', Ember.get(this, 'selected.latitude'));
        Ember.set(this, 'lng', Ember.get(this, 'selected.longitude'));
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          if (Ember.get(self, 'isDestroyed') || Ember.get(self, 'isDestroying')) {
            return;
          }

          Ember.set(self, 'lat', position.coords.latitude);
          Ember.set(self, 'lng', position.coords.longitude);
        });
      }
    },
    actions: {
      onClick: function onClick(e) {
        var location = e.latlng;
        this.send('updateLocation', location);
      },
      onMarkerDragend: function onMarkerDragend(e) {
        var location = e.target.getLatLng();
        this.send('updateLocation', location);
      },
      updateLocation: function updateLocation(location) {
        Ember.set(this, 'lat', location.lat);
        Ember.set(this, 'lng', location.lng);
        var action = this.onSelect;
        action(location.lat, location.lng);
      }
    }
  });

  _exports.default = _default;
});