define("webapp/pods/modules/users/user/email/route", ["exports", "webapp/utils/action-model", "webapp/mixins/routes/dirty-confirm"], function (_exports, _actionModel, _dirtyConfirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dirtyConfirm.default, {
    model: function model() {
      var user = this.modelFor("modules.users.user");

      var model = _actionModel.default.create();

      model.set("content", {
        eMailAddress: ""
      });
      model.fill(user);
      model.applyBufferedChanges();
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, "user", this.modelFor("modules.users.user"));
    }
  });

  _exports.default = _default;
});