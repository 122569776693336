define("webapp/pods/modules/units/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g3wx8q8N",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui stackable grid\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"building outline icon\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n          Gliederungen\\n          \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"Übersicht\"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"eight wide middle aligned column\"],[8],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[9],[0,\"\\n      \"],[1,[28,\"units/index-table\",null,[[\"model\",\"onRowClick\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"transitionToUnit\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\\n\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/units/index/template.hbs"
    }
  });

  _exports.default = _default;
});