define("webapp/pods/components/form-event/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    eventTypesList: Ember.inject.service(),
    unitsList: Ember.inject.service(),
    organizingUsersList: Ember.inject.service(),
    model: null,
    task: null,
    updating: false,
    registrationTypes: null,
    eventItem: null,
    unitId: null,
    initialSetup: false,
    timeCanNotBeAltered: Ember.computed('eventItem', 'currentUser', function () {
      if (Ember.get(this, 'eventItem.timeCanNotBeAltered')) {
        return true;
      }

      if (Ember.get(this, 'eventItem.isReview')) {
        if (Ember.get(this, 'currentUser.model.canManageUsers') || Ember.get(this, 'eventItem.cCanPublish')) {
          return false;
        }

        return true;
      }

      if (Ember.get(this, 'eventItem.isPublished')) {
        if (Ember.get(this, 'currentUser.model.canManageUsers')) {
          return false;
        } else {
          return true;
        }
      }

      return false;
    }),
    startLocationIsDisabled: Ember.computed('eventItem', 'currentUser', function () {
      if (Ember.get(this, 'eventItem.isReview') || Ember.get(this, 'eventItem.isPublished')) {
        if (Ember.get(this, 'currentUser.model.canManageUsers') || Ember.get(this, 'eventItem.cCanPublish') || Ember.get(this, 'eventItem.cCanSupervise')) {
          return false;
        }

        return true;
      }

      return false;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'registrationTypes', [{
        key: 'None',
        name: 'Keine Anmeldung'
      }, {
        key: 'Internal',
        name: 'Teilnehmeranmeldung'
      }, {
        key: 'External',
        name: 'Externe Anmeldung'
      }]);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var that = this;

      that._super.apply(that, arguments);

      if (that.initialSetup != true) {
        Ember.set(that, 'initialSetup', true);
        that.getUnitId(that.unitsList.result);
      }
    },
    actions: {
      save: function save(model) {
        this.task.perform(model);
      },
      organizerDidChange: function organizerDidChange() {
        Ember.set(this, 'model.cPublishOrganizer', false);
      },
      unitDidChange: function unitDidChange() {
        var unitsList = Ember.get(this, 'unitsList.content');
        this.getUnitId(unitsList);
      },
      beginningDidChange: function beginningDidChange() {
        var beginning = Ember.get(this, 'model.beginning');

        if (!beginning) {
          return;
        }

        var end = Ember.get(this, 'model.end');

        if (!end || end < beginning) {
          Ember.set(this, 'model.end', beginning);
        }
      }
    },
    getUnitId: function getUnitId(list) {
      var that = this;
      list.forEach(function (item) {
        if (item.key == Ember.get(that, 'model.unitKey')) {
          Ember.set(that, 'unitId', item.unitId);
          Ember.set(that, 'lastKey', item.key);
          return;
        }
      });
    }
  });

  _exports.default = _default;
});