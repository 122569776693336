define("webapp/pods/components/events/statistics/options-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t2x2t/Et",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"three fields\"],[8],[0,\"\\n    \"],[1,[28,\"alpha-select\",null,[[\"options\",\"displayField\",\"valueField\",\"onChange\",\"selected\",\"class\",\"label\"],[[24,[\"unitsList\",\"content\"]],\"name\",\"key\",[28,\"action\",[[23,0,[]],[28,\"queue\",[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"unitKey\"]]],null]],null],[28,\"action\",[[23,0,[]],\"changed\"],null]],null]],null],[24,[\"unitKey\"]],\"field\",\"Gliederung\"]]],false],[0,\"\\n    \"],[1,[28,\"alpha-select\",null,[[\"options\",\"displayField\",\"valueField\",\"onChange\",\"selected\",\"class\",\"label\"],[[24,[\"years\"]],\"value\",\"value\",[28,\"action\",[[23,0,[]],[28,\"queue\",[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"year\"]]],null]],null]],null]],null],[24,[\"year\"]],\"field\",\"Jahr\"]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"left floated eight wide column\"],[10,\"style\",\"margin-top:22px\"],[8],[0,\"\\n\"],[4,\"alpha-task-button\",null,[[\"class\",\"task\"],[\"ui right floated labeled icon button\",[28,\"task\",[[24,[\"exportTaskStatisticPdf\"]],[24,[\"unitKey\"]],[24,[\"year\"]]],null]]],{\"statements\":[[0,\"        \"],[7,\"i\",true],[10,\"class\",\"download icon\"],[8],[9],[0,\" Statistik als PDF\\n\"]],\"parameters\":[]},null],[4,\"alpha-task-button\",null,[[\"class\",\"task\"],[\"ui right floated labeled icon button\",[28,\"task\",[[24,[\"exportTaskStatisticCsv\"]],[24,[\"unitKey\"]],[24,[\"year\"]]],null]]],{\"statements\":[[0,\"        \"],[7,\"i\",true],[10,\"class\",\"download icon\"],[8],[9],[0,\" Statistik als CSV\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/events/statistics/options-bar/template.hbs"
    }
  });

  _exports.default = _default;
});