define("webapp/pods/components/events/index/selection-actions/request-review/component", ["exports", "webapp/pods/components/events/index/selection-actions/base-modal/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this.parent, 'requestReviewModal', this);
    },
    open: function open() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});