define("webapp/pods/components/alpha-task-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    task: null,
    attributeBindings: ['type', 'task.isRunning:disabled'],
    classNameBindings: ['task.isRunning:loading'],
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(this.task) && Ember.assert("You have to provide a task to `alpha-task-button`", this.task));
    },
    click: function click() {
      this.task.perform();
    }
  });

  _exports.default = _default;
});