define("webapp/pods/components/module-selection/selection-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cERpsyzf",
    "block": "{\"symbols\":[\"selection\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui top attached tabular menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"selections\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"item \",[28,\"if\",[[28,\"is-equal\",[[23,1,[\"id\"]],[24,[\"activeSelection\"]]],null],\"active\"],null]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n        \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"title\"],[12,\"title\",\"Selektion auswählen\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"select\",[23,1,[\"id\"]]],null]]],[3,\"on\",[\"dblclick\",[28,\"action\",[[23,0,[]],\"doubleClicked\",[23,1,[\"id\"]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"title\"]],\"Search\"],null]],null,{\"statements\":[[0,\"              \"],[1,[28,\"format-date\",[[23,1,[\"created\"]]],[[\"format\"],[\"full\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"              \"],[1,[23,1,[\"title\"]],false],[0,\" | \"],[1,[28,\"format-date\",[[23,1,[\"created\"]]],[[\"format\"],[\"full\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n        \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"icon\"],[12,\"title\",\"Selektion updaten\"],[3,\"action\",[[23,0,[]],\"update\",[23,1,[\"id\"]]]],[8],[7,\"i\",true],[10,\"class\",\"blue refresh icon\"],[8],[9],[9],[0,\"\\n        \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"icon\"],[12,\"title\",\"Selektion löschen\"],[3,\"action\",[[23,0,[]],\"remove\",[23,1,[\"id\"]]]],[8],[7,\"i\",true],[10,\"class\",\"red trash icon\"],[8],[9],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/module-selection/selection-tabs/template.hbs"
    }
  });

  _exports.default = _default;
});