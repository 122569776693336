define("webapp/pods/modules/changelog/route", ["exports", "webapp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model() {
      return this.ajax.request("".concat(_environment.default.api.host, "/api/milestones"), {
        headers: {
          Authorization: 'Bearer ' + Ember.get(this, 'session.session.content.authenticated.idToken')
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('config', _environment.default);
    }
  });

  _exports.default = _default;
});