define("webapp/pods/components/alpha-location-select/result-popup/result-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TSaPpwgQ",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"item\",\"display_name\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/alpha-location-select/result-popup/result-item/template.hbs"
    }
  });

  _exports.default = _default;
});