define("webapp/pods/components/alpha-location-select/direct-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "STc+n1Th",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui two column grid\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n      \"],[1,[28,\"alpha-input\",null,[[\"label\",\"value\",\"required\",\"class\"],[\"Bezeichnung\",[24,[\"selected\",\"street\"]],true,\"sixteen wide field\"]]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"sixteen wide field\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"name\",\"button\"],[12,\"class\",\"ui button\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"selectStreet\"]],[8],[0,\"Übernehmen\"],[9],[0,\"\\n     \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/alpha-location-select/direct-input/template.hbs"
    }
  });

  _exports.default = _default;
});