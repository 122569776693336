define("webapp/pods/modules/users/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ppy44jvT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui stackable grid\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"big icons\"],[8],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"user icon\"],[8],[9],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"corner add icon\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n          Benutzer\\n          \"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[0,\"Neuen Benutzer anlegen\"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"eight wide middle aligned column\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"ui left labeled right floated icon button\",\"modules.users.index\"]],{\"statements\":[[0,\"        \"],[7,\"i\",true],[10,\"class\",\"reply icon\"],[8],[9],[0,\"Abbrechen\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n\\n      \"],[1,[28,\"form-user\",null,[[\"model\",\"task\"],[[24,[\"model\"]],[24,[\"save\"]]]]],false],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/users/create/template.hbs"
    }
  });

  _exports.default = _default;
});