define("webapp/pods/modules/users/user/delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g+AojNDg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[10,\"class\",\"ui dividing header\"],[8],[0,\"Löschen\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[0,\"Die Löschung eines Benutzers kann nicht mehr Rückgängig gemacht werden.\"],[9],[0,\"\\n\\n\"],[4,\"alpha-task-button\",null,[[\"class\",\"task\"],[\"ui labeled icon red button\",[24,[\"deleteTask\"]]]],{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"remove icon\"],[8],[9],[0,\" Löschen bestätigen\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/modules/users/user/delete/template.hbs"
    }
  });

  _exports.default = _default;
});