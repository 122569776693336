define("webapp/pods/components/events/index/selection-actions/request-review/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ymqutiu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui modal\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"Veranstaltungen zur Veröffentlichung freigeben\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"scrolling content\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"ui negative message\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n          Fehler\\n        \"],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[1,[22,\"error\"],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Wirklich alle Veranstaltungen zur Veröffentltlichung freigeben die sich in der Selektion befinden?\"],[9],[0,\"\\n\"],[4,\"alpha-task-button\",null,[[\"class\",\"task\"],[\"ui labeled icon primary button\",[24,[\"parent\",\"requestReviewTask\"]]]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[10,\"class\",\"certificate icon\"],[8],[9],[0,\" Zur Prüfung und Veröffentlichung freigeben\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ui cancel button\"],[8],[0,\"Schließen\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/events/index/selection-actions/request-review/template.hbs"
    }
  });

  _exports.default = _default;
});