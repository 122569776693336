define("webapp/pods/application/route", ["exports", "ember-simple-auth-auth0/mixins/application-route-mixin", "ember-cli-nprogress", "ember-ajax/errors"], function (_exports, _applicationRouteMixin, _emberCliNprogress, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      /* NOTE: if you lazily load translations, here is also where you would load them via `intl.addTranslations` */
      this.intl.setLocale(['de-de']);
      /* array optional */

      this.notifications.setDefaultAutoClear(true);
      return this._super(transition);
    },
    actions: {
      loading: function loading(transition) {
        _emberCliNprogress.default.start();

        transition.finally(function () {
          _emberCliNprogress.default.done();
        });
        return true;
      },
      handleSuccess: function handleSuccess(options) {
        var text = 'Änderungen erfolgreich übernommen';

        if (options && options.text) {
          text = options.text;
        }

        this.notifications.success(text, options);
      },
      handleError: function handleError(error) {
        // eslint-disable-next-line no-console
        console.log(error);
        var notify = this.notifications;

        if (error.status >= 400 && error.status < 500) {
          if (error.message) {
            notify.error(error.message);
            return;
          }

          if (Ember.get(error, 'body.message')) {
            notify.error(Ember.get(error, 'body.message'));
          }

          return;
        }

        if (error instanceof _errors.AbortError) {
          notify.error('Verbindung zum Server konnte nicht hergestellt werden.');
          return;
        }

        this.intermediateTransitionTo('error', error);
      }
    }
  });

  _exports.default = _default;
});