define("webapp/pods/auth/activate/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    queryParams: {
      token: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      if (Ember.get(this, 'session.isAuthenticated')) {
        this.notifications.error('Bitte loggen Sie sich aus, um diese Aktion auszuführen.');
        this.transitionTo('index');
      }
    },
    model: function model(params) {
      var model = _actionModel.default.create();

      model.set('content', {
        token: params.token,
        password: ''
      });
      model.applyBufferedChanges();
      return model;
    },
    actions: {
      save: function save(model) {
        var self = this;

        if (Ember.get(model, 'password') != Ember.get(model, 'passwordRepeat')) {
          Ember.set(model, 'errors.passwordRepeat', [{
            message: 'equal'
          }]);
          return;
        }

        var user = this.store.createRecord('user');
        var result = model.getContent();
        user.activate(result).then(function (response) {
          self.store.pushPayload('user', response);
          model.applyBufferedChanges();
          Ember.get(self, 'notifications').success('Sie können sich jetzt einloggen.');
          self.transitionTo('index');
        }).catch(function (error) {
          model.setError(error);
          self.send('handleError', error);
        }).finally(function () {
          user.destroyRecord();
        });
      }
    }
  });

  _exports.default = _default;
});