define("webapp/pods/components/module-selection/query-builder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "osUpi7uq",
    "block": "{\"symbols\":[\"rule\"],\"statements\":[[4,\"if\",[[24,[\"rules\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"ui four column grid\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"rules\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"module-selection/query-builder/rule\",null,[[\"rule\",\"fields\",\"selectableFields\",\"removeRule\"],[[23,1,[]],[24,[\"fields\"]],[24,[\"selectableFields\"]],[28,\"action\",[[23,0,[]],\"removeRule\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"four row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"left floated twelve wide column\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"lt\",[[24,[\"rules\",\"length\"]],1],null]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[10,\"class\",\"infoText\"],[8],[0,\"Sie müssen Ihre Selektion mit einem oder mehreren Filtern einschränken.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"rules\",\"length\"]],1],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"alpha-select\",null,[[\"options\",\"displayField\",\"valueField\",\"selected\"],[[24,[\"_combinings\"]],\"label\",\"key\",[24,[\"combining\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"right floated four wide column\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"ui right floated left labeled icon button\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"addRule\"]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"plus icon\"],[8],[9],[0,\"\\n        Filter hinzufügen\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/module-selection/query-builder/template.hbs"
    }
  });

  _exports.default = _default;
});