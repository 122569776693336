define("webapp/pods/components/alpha-confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5cibkw7q",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui mini modal\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[22,\"text\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"ui small positive left labeled icon button\"],[8],[0,\"\\n      Ja\\n      \"],[7,\"i\",true],[10,\"class\",\"checkmark icon\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"ui small negative button\"],[8],[0,\"\\n      Nein\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/alpha-confirm/template.hbs"
    }
  });

  _exports.default = _default;
});