define("webapp/pods/components/adfc-multiselect/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    placeholder: null,
    _selector: null,
    items: null,
    selected: "",
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      var selector = this.$('.dropdown').dropdown({
        message: {
          addResult: '<b>{term}</b> hinzufügen',
          count: '{count} selektiert',
          maxSelections: 'Max. {maxCount} Selektionen',
          noResults: 'Nichts gefunden.'
        },
        placeholder: this.placeholder,
        values: this.items.map(function (item) {
          return {
            name: Ember.get(item, 'tag'),
            value: Ember.get(item, 'tagId')
          };
        }),
        onAdd: function onAdd(v) {
          var selected = Ember.get(self, 'selected');
          var arr = selected.split('||');
          var index = arr.indexOf(v);

          if (index == -1) {
            arr.push(v);
            Ember.set(self, 'selected', arr.join('||'));
          }
        },
        onRemove: function onRemove(v) {
          var selected = Ember.get(self, 'selected');
          var arr = selected.split('||');
          var index = arr.indexOf(v);

          if (index > -1) {
            arr.splice(index, 1);
          }

          Ember.set(self, 'selected', arr.join('||'));
        }
      });
      Ember.run.next(this, function () {
        var selected = Ember.get(self, 'selected').split('||');

        if (Ember.get(self, 'selected') == '') {
          selected = this.items.filterBy('cPreset', true).getEach('tagId');
        }

        selector.dropdown("set exactly", selected);
      });
      Ember.set(this, '_selector', selector);
    }
  });

  _exports.default = _default;
});