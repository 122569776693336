define("webapp/pods/modules/events/event/series/route", ["exports", "webapp/utils/action-model", "webapp/mixins/routes/dirty-confirm", "moment"], function (_exports, _actionModel, _dirtyConfirm, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_dirtyConfirm.default, {
    model: function model() {
      var eventItem = this.modelFor("modules.events.event");
      var eventSeries = Ember.get(eventItem, "cEventSeries");

      var model = _actionModel.default.create();

      if (eventSeries != null) {
        var date = Ember.get(eventSeries, "endDate");
        Ember.set(model, "content", {
          cronPattern: Ember.get(eventSeries, "cronPattern"),
          endDate: date,
          seriesActivated: true
        });
      } else {
        Ember.set(model, "content", {
          cronPattern: "0 0 1/10 * *",
          endDate: (0, _moment.default)().add(1, "y").toDate(),
          seriesActivated: false
        });
      }

      Ember.set(model, "disabled", !Ember.get(eventItem, "isReview") && !Ember.get(eventItem, "isDraft"));
      model.applyBufferedChanges();
      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.set(controller, "eventItem", this.modelFor("modules.events.event"));
    }
  });

  _exports.default = _default;
});