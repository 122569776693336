define("webapp/pods/components/cron-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: null,
    disabled: false,
    _cronWeekDay: Ember.computed('value', function () {
      return this._cron.weekDay;
    }),
    _cronMonth: Ember.computed('value', function () {
      return this._cron.month;
    }),
    _cronDay: Ember.computed('value', function () {
      return this._cron.day;
    }),
    _isDaily: Ember.computed('value', function () {
      var day = this._cron.day;
      return day != '*' && day != '?';
    }),
    _isWeekly: Ember.computed('value', function () {
      return !this._isDaily && !this._isMonthly;
    }),
    _isMonthly: Ember.computed('value', function () {
      var month = this._cron.month;
      return month != '*' && month != '?';
    }),
    _cron: Ember.computed('value', function () {
      var value = this.value;
      var arr = value.split(' ');
      var result = {
        weekDay: arr[4],
        month: arr[3],
        day: arr[2],
        hour: arr[1],
        minute: arr[0]
      };
      return result;
    }),
    onChange: function onChange(cron) {},
    actions: {
      preset: function preset(type) {
        if (this.disabled) {
          return;
        }

        var cron = '';

        switch (type) {
          case 'daily':
            cron = '0 0 1/10 * ?';
            break;

          case 'weekly':
            cron = '0 0 ? * MON,SUN';
            break;

          case 'monthly':
            cron = '0 10 ? 1/2 MON#2';
            break;

          default:
        }

        var action = this.onChange;
        action(cron);
      },
      onChange: function onChange(cron) {
        var action = this.onChange;
        action(cron);
      }
    }
  });

  _exports.default = _default;
});