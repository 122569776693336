define("webapp/serializers/event-item-file", ["exports", "alpha-data/serializers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    primaryKey: 'eventItemFileId'
  });

  _exports.default = _default;
});