define("webapp/models/unit-location", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    street: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    zipCode: (0, _model.attr)('string'),
    latitude: (0, _model.attr)('number', {
      defaultValue: ''
    }),
    longitude: (0, _model.attr)('number', {
      defaultValue: ''
    }),
    unitId: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    unit: (0, _model.belongsTo)('unit'),
    address: Ember.computed('street', 'city', 'zipCode', function () {
      return "".concat(this.street, " ").concat(this.city, " ").concat(this.zipCode);
    })
  });

  _exports.default = _default;
});