define("webapp/pods/modules/users/user/units/route", ["exports", "webapp/utils/action-model"], function (_exports, _actionModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('modules.users.user');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var userUnitModel = _actionModel.default.create();

      userUnitModel.set('content', {
        overwrite: false,
        unitId: null,
        roleId: null
      });
      Ember.set(controller, 'userUnitModel', userUnitModel);
    }
  });

  _exports.default = _default;
});