define("webapp/pods/components/alpha-location-select/result-popup/result-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item'],
    classNameBindings: 'active',
    item: null,
    active: false,
    onSelect: function onSelect() {},
    click: function click() {
      var action = this.onSelect;
      action(this.item);
    }
  });

  _exports.default = _default;
});