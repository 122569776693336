define("webapp/models/tag", ["exports", "alpha-data/models/tag", "@ember-data/model"], function (_exports, _tag, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import DS from 'ember-data';
  var _default = _tag.default.extend({
    tagId: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    cType: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    cRequired: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cPreset: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    cOrder: (0, _model.attr)('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});