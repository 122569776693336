define("webapp/services/tour-attributes-list", ["exports", "alpha-data/mixins/ressource-list-mixin"], function (_exports, _ressourceListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_ressourceListMixin.default, {
    modelName: 'tour-attribute',
    speeds: Ember.computed('content', function () {
      return this._filtered('speed');
    }),
    lengths: Ember.computed('content', function () {
      return this._filtered('length');
    }),
    surfaces: Ember.computed('content', function () {
      return this._filtered('surface');
    }),
    climbes: Ember.computed('content', function () {
      return this._filtered('climb');
    }),
    _filtered: function _filtered(type) {
      return this.content.filterBy('type', type).sortBy('index');
    }
  });

  _exports.default = _default;
});