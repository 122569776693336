define("webapp/pods/auth/login/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    actions: {
      login: function login() {
        var urlAfterLogin = this.session.attemptedTransition ? this.session.attemptedTransition.intent.url : undefined;

        if (urlAfterLogin) {
          localStorage.setItem('urlAfterLogin', urlAfterLogin);
        } // Check out the docs for all the options:
        // https://auth0.com/docs/libraries/lock/customization


        var lockOptions = {
          rememberLastLogin: false,
          configurationBaseUrl: 'https://cdn.eu.auth0.com',
          auth: {
            redirect: true,
            params: {
              scope: 'openid email user_metadata' //audience: '', // Unklar
              //responseType: 'token id_token'

            }
          },
          autoclose: true,
          language: 'de',
          autofocus: true,
          closable: true,
          allowAutocomplete: true,
          allowShowPassword: true,
          theme: {
            logo: '/logo.png',
            primaryColor: '#ee7f00'
          },
          languageDictionary: {
            emailInputPlaceholder: 'Ihre E-Mail-Adresse',
            title: ""
          }
        };

        if (!Ember.get(this, 'session.isAuthenticated')) {
          this.session.authenticate('authenticator:auth0-lock', lockOptions);
        }
      }
    }
  });

  _exports.default = _default;
});