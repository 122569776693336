define("webapp/helpers/organizer-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.organizerTitle = organizerTitle;

  function organizerTitle(params
  /*, hash*/
  ) {
    if (params[0] === 'Radtour') {
      return 'Tourleiter/in';
    }

    return 'Ansprechpartner/in';
  }

  var _default = Ember.Helper.helper(organizerTitle);

  _exports.default = _default;
});