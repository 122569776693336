define("webapp/initializers/notifications", ["exports", "ember-cli-notifications/services/notification-messages-service"], function (_exports, _notificationMessagesService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'notification-messages-service',
    initialize: function initialize() {
      var application = arguments[1] || arguments[0];

      if (Ember.Service) {
        application.register('service:notification-messages', _notificationMessagesService.default);
        application.inject('component:notification-container', 'notifications', 'service:notification-messages');
        application.inject('component:notification-message', 'notifications', 'service:notification-messages');
        return;
      }

      application.register('notification-messages:service', _notificationMessagesService.default);
      ['controller', 'component', 'route', 'router', 'service'].forEach(function (injectionTarget) {
        application.inject(injectionTarget, 'notifications', 'notification-messages:service');
      });
    }
  };
  _exports.default = _default;
});