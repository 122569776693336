define("webapp/pods/components/events/calendar/options-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3p+V50dZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"three fields\"],[8],[0,\"\\n    \"],[1,[28,\"alpha-select\",null,[[\"options\",\"displayField\",\"valueField\",\"onChange\",\"selected\",\"class\",\"label\"],[[24,[\"unitsList\",\"content\"]],\"name\",\"key\",[28,\"action\",[[23,0,[]],[28,\"queue\",[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"unitKey\"]]],null]],null],[28,\"action\",[[23,0,[]],\"changed\"],null]],null]],null],[24,[\"unitKey\"]],\"field\",\"Gliederung\"]]],false],[0,\"\\n    \"],[1,[28,\"alpha-calendar\",null,[[\"value\",\"onChange\",\"class\",\"label\"],[[24,[\"from\"]],[28,\"action\",[[23,0,[]],[28,\"queue\",[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"from\"]]],null]],null],[28,\"action\",[[23,0,[]],\"changed\"],null]],null]],null],\"field\",\"Von\"]]],false],[0,\"\\n    \"],[1,[28,\"alpha-calendar\",null,[[\"value\",\"onChange\",\"class\",\"label\"],[[24,[\"to\"]],[28,\"action\",[[23,0,[]],[28,\"queue\",[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"to\"]]],null]],null],[28,\"action\",[[23,0,[]],\"changed\"],null]],null]],null],\"field\",\"Bis\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/events/calendar/options-bar/template.hbs"
    }
  });

  _exports.default = _default;
});