define("webapp/pods/modules/events/event/finish/final-invoice/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var eventItem = this.modelFor("modules.events.event");

      if (!eventItem.cCanFinish) {
        this.transitionTo("modules.events.event.index", eventItem);
      }
    },
    model: function model() {
      return this.modelFor("modules.events.event");
    }
  });

  _exports.default = _default;
});