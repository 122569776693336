define("webapp/pods/modules/events/event/finish/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      updateParticipants: function updateParticipants(priceModel, participantsAmount) {
        var test = Ember.get(this, 'finishModel.additionalParticipants');
        console.log(Ember.get(priceModel, 'groupName'));
        test[Ember.get(priceModel, 'groupName')] = participantsAmount; //set(test, get(priceModel, 'groupName'), participantsAmount);

        console.log(test);
      }
    }
  });

  _exports.default = _default;
});