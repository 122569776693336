define("webapp/pods/components/module-selection/query-builder/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fields: null,
    rules: null,
    selectableFields: null,
    combining: null,
    onChange: function onChange() {//console.error('alpha-query-builder: onChange action is not implemented');
    },
    _combinings: [{
      label: 'Alle Filter müssen zutreffen (UND)',
      key: 'and'
    }, {
      label: 'Mindestens ein Filter muss zutreffen (ODER)',
      key: 'or'
    }],
    actions: {
      addRule: function addRule() {
        var rules = this.rules;
        var field = this.selectableFields;
        rules.pushObject(Ember.Object.create({
          field: field[0].id,
          operator: 'equals',
          operand: null
        }));

        this._onChange({
          combining: this._condition,
          rules: rules
        });
      },
      removeRule: function removeRule(rule) {
        var rules = this.rules;
        rules.removeObject(rule);

        this._onChange({
          combining: this._condition,
          rules: rules
        });
      }
    },
    _onChange: function _onChange(rules) {
      var action = this.onChange;

      if (!rules.rules.length) {
        action(null);
        return;
      }

      action(rules);
    }
  });

  _exports.default = _default;
});