define("webapp/pods/components/image-copyright-reminder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4rcS1ohs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"accepted\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui warning message\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n      Hinweis zum Urheberrecht bei Bildern\\n    \"],[9],[0,\"\\n    Bitte stelle sicher, dass mit der Verwendung des Bildes kein Urheberrecht verletzt wird. Hinweise zum Thema findest du im\\n    \"],[7,\"a\",true],[10,\"href\",\"https://www.adfc.de/fileadmin/user_upload/Bilderpool/Redaktionshandbuch_RVP.pdf\"],[8],[0,\"Handbuch (Absatz \\\"Bilder\\\")\"],[9],[0,\". Nutze alternativ Bilder aus dem\\n    Bilderpool.\\n\\n    \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"ui green left labeled icon button\"],[3,\"action\",[[23,0,[]],\"accept\"]],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"left check icon\"],[8],[9],[0,\"\\n      Bedingungen akzeptieren\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "webapp/pods/components/image-copyright-reminder/template.hbs"
    }
  });

  _exports.default = _default;
});